// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../../images/poster.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.hero-video-container{margin:auto;max-width:1440px;overflow:hidden\n}\n.hero-video-container .video-js{height:auto;width:auto\n}\n.hero-video-container video{background:rgba(0,0,0,0) url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 0 0;background-size:cover;height:434px;width:1440px\n}\n.hero-video-container .video-bg-dimensions{height:inherit;width:inherit\n}\n.hero-video-container .vjs-text-track-display,.hero-video-container .vjs-loading-spinner,.hero-video-container .vjs-big-play-button,.hero-video-container .vjs-big-play-button,.hero-video-container .vjs-control-bar,.hero-video-container .vjs-error-display,.hero-video-container .vjs-modal-dialog,.hero-video-container .vjs-resize-manager{display:none\n}", "",{"version":3,"sources":["webpack://./src/components/home/HeroVideo.vue"],"names":[],"mappings":";AA6BA,sBACC,WAAA,CACA,gBAAA,CACA;AA9BD;AAgCC,gCACC,WAAA,CACA;AAhCF;AAmCC,4BACC,8EAAA,CACA,qBAAA,CACA,YAAA,CACA;AArCF;AAwCC,2CACC,cAAA,CACA;AAxCF;AA2CC,iVAQC;AAjDF","sourcesContent":["\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n.hero-video-container {\n\tmargin: auto;\n\tmax-width: 1440px;\n\toverflow: hidden;\n\n\t.video-js {\n\t\theight: auto;\n\t\twidth: auto;\n\t}\n\n\tvideo {\n\t\tbackground: transparent url(\"../../images/poster.jpg\") no-repeat 0 0;\n\t\tbackground-size: cover;\n\t\theight: 434px;\n\t\twidth: 1440px;\n\t}\n\n\t.video-bg-dimensions {\n\t\theight: inherit;\n\t\twidth: inherit;\n\t}\n\n\t.vjs-text-track-display,\n\t.vjs-loading-spinner,\n\t.vjs-big-play-button,\n\t.vjs-big-play-button,\n\t.vjs-control-bar,\n\t.vjs-error-display,\n\t.vjs-modal-dialog,\n\t.vjs-resize-manager {\n\t\tdisplay: none;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
