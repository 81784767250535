var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "newsLetterSignup" },
    [
      _c("h2", [_vm._v("Want to Stay Up to Date? Sign Up for Our Emails")]),
      _c("p", [
        _vm._v("Receive product updates and promotional news from MITY Inc.")
      ]),
      _c("HubSpotForm", { attrs: { HubspotFormId: _vm.HubspotFormId } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4a349641", { render: render, staticRenderFns: staticRenderFns })
  }
}