<template lang="pug">
	.page-damage
		Header
		main
			Logos

			h2 Reporting Freight Damage

			h3 Did you find freight damage after inspecting items in your order?

			.warning 
				p <strong> Please Note:</strong>
				ul
					li Freight damages must be reported <strong>within 48 hours of shipment receipt.</strong>
					li Failure to file the claim within 48 hours <strong>could void the warranty on your products.</strong>

			p To submit your damage report, please provide the following information:

			.form 
				script(type="application/javascript").
					hbspt.forms.create({ region: "na1", portalId: "6724089", formId:"c65317f8-bb44-464f-8606-8cee3755f556" });

		BrandColors

		Footer
</template>

<script>
import Header from "../components/Header.vue";
import Logos from "../components/home/MityBrandLogos.vue";
import BrandColors from "../components/home/BrandColors.vue";
import Footer from "../components/Footer.vue";

export default {
	name: "Damage",
	metaInfo: {
		title: "Report Freight Damage | MITY Inc.",
		meta: [
			{ name: "description", content: "Report any freight damage with MITY Inc." },
			{ name: "robots", content: "noindex, nofollow" },
		],
	},
	data() {
		return {};
	},
	components: { Header, Logos, BrandColors, Footer },
};
</script>

<style lang="scss">
@import "../style/mityinc-browser.scss";

.page-damage {
	min-width: 320px;

	header.header {
		.container {
			nav,
			.hamburger-icon {
				display: none;
			}
		}
	}

	main {
		margin: 92px auto 0;
		max-width: 800px;

		.mity-brand-logos {
			display: flex;
			max-width: none;
			padding: 2.5em 0;

			a:last-child {
				display: none;
			}
		}

		h2,
		h3 {
			line-height: 130%;
			text-align: left;
		}

		h2 {
			font-size: 1.5em;
			font-weight: 500;
			margin-bottom: 0.8em;
			text-transform: uppercase;
		}

		h3 {
			font-size: 1.25em;
			margin-bottom: 1.2em;
		}

		p {
			font-size: 1.13em;
			line-height: 130%;
			margin-bottom: 1.5em;
		}

		.warning {
			background-color: #fbf3e4;
			margin-bottom: 1.35em;
			padding: 1em 1.5em;

			p {
				margin: 0.5em 0;
				strong {
					font-weight: 500;
					text-transform: uppercase;
				}
			}

			ul {
				margin-left: 1.5em;
				li {
					font-size: 1.125em;
					line-height: 130%;
					list-style-type: disc;
					margin-bottom: 0.5em;

					&:last-child {
						margin: 0;
					}
				}
			}
		}

		.form {
			margin-bottom: 2.5em;

			.hbspt-form form {
				display: block;
				max-width: none;

				fieldset {
					max-width: none;
				}

				.hs-fieldtype-text {
					margin-bottom: 1.5em;
					max-width: none;
				}

				.hs-fieldtype-radio {
					line-height: 150%;

					label {
						display: block;
						padding-top: 0.5em;
					}
				}

				input[type="text"],
				input[type="tel"],
				input[type="email"] {
					background-color: #f5f8fa;
					border: 1px #cbd6e2 solid;
					width: 100%;

					&.error {
						border: 1px red solid;
					}
				}

				input[type="radio"] {
					height: 20px;
					margin-right: 0.5em;
					vertical-align: sub;
					width: 20px;
				}

				input[type="submit"] {
					background-color: $mity-blue;
					border-radius: 5px;
					font-size: 1em;
					margin: 2em 0 1em;
					padding: 0.5em 3em;

					&:hover {
						background-color: $blue;
					}
				}

				.hs-form-radio {
					margin: 0.5em 0;
				}

				.hs_email > label {
					display: block;
				}

				.hs-field-desc {
					color: #666;
					font-size: 0.9em;
					line-height: 120%;
					margin-bottom: 1em;
				}

				span.hs-form-required {
					color: red;
					margin-left: 0.25em;
				}
			}

			.hs-error-msg {
				color: red;
			}
		}
	}

	footer.footer-section {
		.footer-mity-brand-logos {
			.logo-image {
				display: block;
			}

			.brand-image {
				display: none;
			}
		}

		.social-email {
			display: none;
		}
	}
}

@media (max-width: 1050px) {
	.page-damage {
		main {
			padding: 0 1.35em;
		}
	}
}

@media (max-width: 600px) {
	.page-damage {
		main {
			.mity-brand-logos {
				padding: 1em 0 0.5em;

				a {
					line-height: 100%;
					width: 33%;

					&:nth-child(2) {
						width: 26%;
					}

					svg {
						max-height: 45px;
						width: 100%;
					}
				}
			}

			.form .hbspt-form form .hs-fieldtype-radio {
				margin-top: 1.5em;
			}
		}
	}
}
</style>
