<script>
export default {
	created() {
		window.addEventListener("scroll", this.handleScroll);
	},
	methods: {
		handleScroll: function() {
			if (screen.width >= 1100 && window.scrollY >= 450) {
				this.activateStatsAnimation();
			} else if (screen.width < 1100 && screen.width >= 660 && window.scrollY >= 850) {
				this.activateStatsAnimation();
			} else if (screen.width < 660 && screen.width >= 470 && window.scrollY >= 1000) {
				this.activateStatsAnimation();
			} else if (screen.width < 470 && window.scrollY >= 1200) {
				this.activateStatsAnimation();
			}
		},
		activateStatsAnimation: function() {
			this.circleProgress("table-stats", 1200000, "Tables Made", 50);
			this.circleProgress("chair-stats", 4800000, "Chairs Made", 50);
			this.circleProgress("client-stats", 30000, "Clients Served", 50);
			window.removeEventListener("scroll", this.handleScroll);
		},
		circleProgress: function(selector, end, title, area) {
			let wrapper = document.getElementsByClassName(selector)[0];
			function easeInOutQuart(currentTime, beginningValue, changeInValue, duration) {
				if ((currentTime /= duration / 2) < 1) {
					return (changeInValue / 2) * currentTime * currentTime * currentTime * currentTime + beginningValue;
				} else {
					return (-changeInValue / 2) * ((currentTime -= 2) * currentTime * currentTime * currentTime - 2) + beginningValue;
				}
			}

			let canvas, context, centerX, centerY, duration, innerHTML, lineWidth, newPercent, percent, radius, start, startTimestamp, strokeStyle, text, wrapperHeight, wrapperWidth, gradient;
			let getValues = function() {
				wrapperWidth = parseInt(window.getComputedStyle(wrapper).width);
				wrapperHeight = wrapperWidth;
				percent = 100;
				innerHTML = '<span class="percentage"><strong>' + percent + "</strong>+</span><p>" + title + '</p><canvas class="circleProgressCanvas" width="' + wrapperWidth * 2 + '" height="' + wrapperHeight * 2 + '"></canvas>';
				wrapper.innerHTML = innerHTML;
				text = wrapper.querySelector(".percentage");
				canvas = wrapper.querySelector(".circleProgressCanvas");
				wrapper.style.height = canvas.style.width = canvas.style.height = wrapperWidth + "px";
				context = canvas.getContext("2d");
				centerX = canvas.width / 2;
				centerY = canvas.height / 2;
				duration = 3000;
				lineWidth = 50;
				radius = canvas.width / 2 - lineWidth;
				gradient = context.createLinearGradient(0, 0, canvas.width, 0);
				gradient.addColorStop("0", "#28558c");
				gradient.addColorStop("1.0", "#53a37b");
				strokeStyle = gradient;
				start = new Date().getTime();
			};

			const animate = (timestamp) => {
				if (!startTimestamp) startTimestamp = timestamp;
				const progress = Math.min((timestamp - startTimestamp) / duration, 1);
				let newNumber = Math.floor(progress * end);
				let currentTime = new Date().getTime() - start;
				if (currentTime <= duration) {
					requestAnimationFrame(animate);
					newPercent = easeInOutQuart(currentTime, 0, 100 - 0, duration);
					text.innerHTML = Number(newNumber).toLocaleString() + " +";
					drawArc();
				} else {
					text.innerHTML = Number(end).toLocaleString() + " +";
				}
			};

			function drawArc() {
				let circleStart = 1.5 * Math.PI;
				let circleEnd = circleStart + (newPercent / area) * Math.PI;
				context.clearRect(0, 0, canvas.width, canvas.height);
				context.beginPath();
				context.arc(centerX, centerY, radius, circleStart, 4 * Math.PI, false);
				context.lineWidth = lineWidth;
				context.strokeStyle = "#ddd";
				context.stroke();
				context.beginPath();
				context.arc(centerX, centerY, radius, circleStart, circleEnd, false);
				context.lineWidth = lineWidth;
				context.strokeStyle = strokeStyle;
				context.stroke();
			}
			var update = function() {
				getValues();
				requestAnimationFrame(animate);
			};
			update();

			let resizeTimer;
			window.addEventListener("resize", function() {
				clearTimeout(resizeTimer);
				resizeTimer = setTimeout(function() {
					clearTimeout(resizeTimer);
					start = new Date().getTime();
					update();
				}, 250);
			});
		},
	},
};
</script>

<template lang="pug">
	section.product-stats
		.product-stat
			.chair-stats
		.product-stat
			.table-stats
		.product-stat
			.client-stats
</template>

<style lang="scss">
@import "../../style/mityinc-browser.scss";
.product-stats {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	margin: auto;
	max-width: 1200px;
	padding: 50px 0;
	&.before-enter {
		opacity: 0;
		transform: translateY(100px);
		transition: all 2s ease-out;
	}
	&.enter {
		opacity: 1;
		transform: translateY(0px);
	}
	.product-stat {
		& > div {
			display: inline-flex;
			flex-direction: column;
			width: 300px;
			height: 300px;
			max-width: 100%;
			position: relative;
			justify-content: center;
			align-items: center;
			font-size: calc(1.1em + 1vmin);
			transition: height 0.2s ease-in-out;
			background: #fff;
			border-radius: 50%;
			margin: 1em 0;

			p {
				font-size: 0.8em;
			}
		}
		canvas {
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}
</style>
