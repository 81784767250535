var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 24 24" },
      attrs: {
        version: "1.1",
        id: "Layer_1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 24 24",
        "xml:space": "preserve"
      }
    },
    [
      _c("g", [
        _c("path", {
          staticClass: "st0",
          attrs: {
            d:
              "M19,0H5C2.2,0,0,2.2,0,5v14c0,2.8,2.2,5,5,5h14c2.8,0,5-2.2,5-5V5C24,2.2,21.8,0,19,0z M8,19H5V8h3V19zM6.5,6.7c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8S8.2,4,8.2,5S7.5,6.7,6.5,6.7z M20,19h-3v-5.6c0-3.4-4-3.1-4,0V19h-3V8h3v1.8c1.4-2.6,7-2.8,7,2.5V19z"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-dfe3089c", { render: render, staticRenderFns: staticRenderFns })
  }
}