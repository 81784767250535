var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hero-video-container", attrs: { id: "home" } },
    [
      _c(
        "video",
        {
          staticClass: "video-js",
          attrs: {
            id: "video-bg",
            muted: "muted",
            playsinline: "playsinline",
            autoplay: "autoplay",
            loop: "loop",
            preload: "auto",
            poster: require("../../images/poster.jpg"),
            "data-setup": "{}"
          },
          domProps: { muted: true }
        },
        [
          _c("source", {
            attrs: {
              src: require("../../images/MITY_CorporateWebsite_Header_Video.mp4"),
              type: "video/mp4"
            }
          }),
          _c("p", { staticClass: "vjs-no-js" }, [
            _vm._v(
              "To view this video please enable JavaScript, and consider upgrading to a web browser that supports HTML5 video"
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-31e2de3e", { render: render, staticRenderFns: staticRenderFns })
  }
}