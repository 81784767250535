var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 496.07 76.31"
      }
    },
    [
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M468.63,70.92H458.57V.82h10.06ZM443,.82v70.1h-8.63L387.53,17v54h-9.46V.82h8L433.58,55.1V.82Zm-80.38,70.1H352.58V.82h10.08ZM312.08.82V61.88h31.66v9H302V.82ZM224.8,35.87q0,11.63,7.77,19.23t19.49,7.61q11.81,0,19.57-7.61A25.67,25.67,0,0,0,279.4,36q0-11.61-7.77-19.18T252.06,9.25q-11.73,0-19.49,7.55T224.8,36Zm-10.36,0A35.13,35.13,0,0,1,224.7,10.69Q235,0,252.06,0q17.35,0,27.54,10.64A35.43,35.43,0,0,1,289.78,36,35.67,35.67,0,0,1,279.7,61q-10.06,10.84-27.86,10.84-17.15,0-27.28-10.79A35.46,35.46,0,0,1,214.44,36Zm.3-35v9H190.48V70.92H180.4v-61H156.15v-9ZM119.55,9.46v22H126q8,.11,10.89-3.35a11.72,11.72,0,0,0,2.88-7.75v-.21a10.24,10.24,0,0,0-3.29-7.83Q133.32,9.47,126,9.46ZM109.48.82h17.68Q137.84.93,144,6.17t6.16,14.39a18.12,18.12,0,0,1-3,10.49,18.55,18.55,0,0,1-8.68,6.68,27.53,27.53,0,0,1,5.39,4.62,53.35,53.35,0,0,1,4.68,6.06c.88,1.31,1.8,2.7,2.72,4.17s1.87,3,2.83,4.58c.4.68.8,1.32,1.19,2a20.29,20.29,0,0,0,1.17,1.74c.07.14.16.3.26.47a2.28,2.28,0,0,0,.36.46c.82,1.24,1.75,2.56,2.77,4s2.3,3.15,3.81,5.19h-12l-9.24-14.39q-5.87-9-9.46-12.17a13.41,13.41,0,0,0-6.79-3.55c-.42-.07-.8-.12-1.18-.16s-.77,0-1.18,0h-4.22V70.92H109.48ZM97.66.82v9H68V31.05H96.63v9H68V61.88H98.58v9H57.87V.82Zm-87.59,9v21h6.69q7.5.11,11.15-2.69t3.65-8.47a10,10,0,0,0-2.63-6.87c-1.74-2-4.95-2.94-9.6-2.94Zm0,30v22H22.72Q30.73,62,34,59a10.6,10.6,0,0,0,3.65-6.63,3,3,0,0,0,.11-.76v-.77a9.62,9.62,0,0,0-3.8-7.9q-3.81-3.06-11.73-3ZM0,70.92V.82H20.35c7.41.07,12.81,1.9,16.25,5.51a17.75,17.75,0,0,1,5.34,11.86v.62a15.16,15.16,0,0,1-2.62,9.1,18.18,18.18,0,0,1-8.07,5.91v.1q7.92,1.55,12.39,6.42a17.11,17.11,0,0,1,4.47,12c0,.54,0,1.09,0,1.64a8.48,8.48,0,0,1-.26,1.64q-1.14,5.76-6.69,10.54T24.05,70.92Z"
        }
      }),
      _c("polygon", {
        staticClass: "cls-1",
        attrs: {
          points:
            "476.48 0.58 476.48 1.51 479.46 1.51 479.46 9.47 480.47 9.47 480.47 1.51 483.45 1.51 483.45 0.58 476.48 0.58"
        }
      }),
      _c("polygon", {
        staticClass: "cls-1",
        attrs: {
          points:
            "485.33 0.58 486.34 0.58 489.58 5.43 492.82 0.58 493.83 0.58 493.83 9.47 492.83 9.47 492.83 2.27 489.6 7.03 489.54 7.03 486.31 2.28 486.31 9.47 485.33 9.47 485.33 0.58"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-732689a2", { render: render, staticRenderFns: staticRenderFns })
  }
}