<script>
import videojs from "video.js";

export default {
	methods: {
		autoPlay() {
			var player = videojs("video-bg");
			player.ready(function() {
				setTimeout(function() {
					player.autoplay("muted");
					player.play();
				}, 500);
			});
		},
	},
	mounted: function() {
		this.autoPlay();
	},
};
</script>

<template lang="pug">
    #home.hero-video-container
        video(id="video-bg" class="video-js" muted playsinline autoplay loop preload="auto" poster="../../images/poster.jpg" data-setup='{}')
            source(src="../../images/MITY_CorporateWebsite_Header_Video.mp4" type="video/mp4")
            p(class="vjs-no-js") To view this video please enable JavaScript, and consider upgrading to a web browser that supports HTML5 video
</template>

<style lang="scss">
.hero-video-container {
	margin: auto;
	max-width: 1440px;
	overflow: hidden;

	.video-js {
		height: auto;
		width: auto;
	}

	video {
		background: transparent url("../../images/poster.jpg") no-repeat 0 0;
		background-size: cover;
		height: 434px;
		width: 1440px;
	}

	.video-bg-dimensions {
		height: inherit;
		width: inherit;
	}

	.vjs-text-track-display,
	.vjs-loading-spinner,
	.vjs-big-play-button,
	.vjs-big-play-button,
	.vjs-control-bar,
	.vjs-error-display,
	.vjs-modal-dialog,
	.vjs-resize-manager {
		display: none;
	}
}
</style>
