var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "vision-mission-values" }, [
      _c("div", { staticClass: "vision-mission-container" }, [
        _c("div", { staticClass: "vision-mission-relative-container" }, [
          _c("div", { staticClass: "vision-mission-inner-container" }, [
            _c("div", { staticClass: "vision" }, [
              _c("img", {
                attrs: {
                  src: require("../../images/company_values/2024_vision_icon.png"),
                  alt: "human eye icon"
                }
              }),
              _c("h3", [_vm._v("Vision")]),
              _c("p", [
                _vm._v(
                  "To furnish spaces for memorable gatherings, meaningful learning, and face-to-face connections."
                )
              ])
            ]),
            _c("div", { staticClass: "mission" }, [
              _c("img", {
                attrs: {
                  src: require("../../images/company_values/2024_mission_icon.png"),
                  alt: "arrow hitting bulls eye icon"
                }
              }),
              _c("h3", [_vm._v("Mission")]),
              _c("p", [
                _vm._v(
                  "We take pride in manufacturing high-value, easy-to-use products that our customers trust while improving the lives of our employees and exceeding the expectations of our stakeholders."
                )
              ])
            ])
          ]),
          _c("h2", [_vm._v("COMPANY VALUES")])
        ])
      ]),
      _c("div", { staticClass: "values-container" }, [
        _c("div", { staticClass: "value" }, [
          _c("img", {
            attrs: {
              src: require("../../images/company_values/2020_MITYInc_Website_Pieces_MakeItSafe_Graphic.png"),
              alt: "stars over shield icon"
            }
          }),
          _c("h3", [_vm._v("Make It Safe")]),
          _c("p", [
            _vm._v(
              "We strive to be a safe place for employees to work, a safe choice for customers, and a safe investment for our stakeholders."
            )
          ])
        ]),
        _c("div", { staticClass: "value" }, [
          _c("img", {
            attrs: {
              src: require("../../images/company_values/2020_MITYInc_Website_Pieces_ImproveEachDay_Graphic.png"),
              alt: "rocket launching icon"
            }
          }),
          _c("h3", [_vm._v("Improve Each Day")]),
          _c("p", [
            _vm._v(
              "At MITY Inc., we focus on improving customer relationships, improving product innovation to anticipate market needs, and creating new processes that provide greater value and eliminate waste."
            )
          ])
        ]),
        _c("div", { staticClass: "value" }, [
          _c("img", {
            attrs: {
              src: require("../../images/company_values/2020_MITYInc_Website_Pieces_TrustIsKey_Graphic.png"),
              alt: "umbrella icon"
            }
          }),
          _c("h3", [_vm._v("Trust is Key")]),
          _c("p", [
            _vm._v(
              "The MITY Inc. leadership works to build trust through their words, actions, and company results. We earn trust through corroboration, transparency, and timely communication."
            )
          ])
        ]),
        _c("div", { staticClass: "value" }, [
          _c("img", {
            attrs: {
              src: require("../../images/company_values/2020_MITYInc_Website_Pieces_YouMatter_Graphic.png"),
              alt: "two hands holding heart icon"
            }
          }),
          _c("h3", [_vm._v("You Matter")]),
          _c("p", [
            _vm._v(
              "We want all our employees to return home every day feeling accomplished and valued. We hope they take pride in owning their role and sharing knowledge."
            )
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-daa4c82e", { render: render, staticRenderFns: staticRenderFns })
  }
}