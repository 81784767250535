var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("Header"),
      _vm._v(" "),
      _c("HeroVideo"),
      _c(
        "main",
        [
          _c("BrandColors", { staticClass: "top-brand-color" }),
          _vm._v(" "),
          _c("Brands"),
          _vm._v(" "),
          _c("BrandsIntro"),
          _vm._v(" "),
          !_vm.isIE11 ? _c("ProductStats") : _vm._e(),
          _vm._v(" "),
          _c("ProductPictures"),
          _vm._v(" "),
          _c("OurPartners"),
          _vm._v(" "),
          _c("Testimonials"),
          _vm._v(" "),
          !_vm.isIE11 ? _c("newsLetterSignup") : _vm._e(),
          _vm._v(" "),
          _c("About"),
          _vm._v(" "),
          _c("WhatWeDo"),
          _vm._v(" "),
          _c("VissionMissionValues"),
          _vm._v(" "),
          _c("Leadership"),
          _vm._v(" "),
          _c("MityBrandLogos"),
          _vm._v(" "),
          _c("WorkWithUs"),
          _vm._v(" "),
          _c("BrandColors", { staticClass: "bottom-brand-color" }),
          _vm._v(" "),
          _c("Footer")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-fae5bece", { render: render, staticRenderFns: staticRenderFns })
  }
}