<script>
export default {
	props: {
		HubspotFormId: String,
	},
};
</script>

<template lang="pug">
    #hubspot-form
        script(type="application/javascript").
            hbspt.forms.create({ region: "na1", portalId: "6724089", formId:"{{HubspotFormId}}" });
</template>

<style lang="scss">
@import "../../style/mityinc-browser.scss";
</style>
