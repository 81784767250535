<script>
export default {};
</script>

<template lang="pug">
	.brand-color-break
		.bright-orange
		.moderate-orange
		.dark-grayish-lime-green
		.dark-moderage-green
		.very-soft-orange
		.bright-orange2
		.dark-blue
		.very-dark-blue
		.desaturated-dark-red
		.dark-red
</template>

<style lang="scss">
.brand-color-break {
	display: flex;
	flex-direction: row;
	margin: 5px 0;
	> div {
		height: 10px;
		margin: 0 3px;
	}
	.bright-orange {
		background-color: #db803c;
		flex: 14;
		margin: 0 3px 0 0;
	}
	.moderate-orange {
		background-color: #c5693f;
		flex: 11;
	}
	.dark-grayish-lime-green {
		background-color: #809980;
		flex: 4;
	}
	.dark-moderage-green {
		background-color: #61803d;
		flex: 14;
	}
	.very-soft-orange {
		background-color: #e3bb80;
		flex: 6;
	}
	.bright-orange2 {
		background-color: #e49d41;
		flex: 11;
	}
	.dark-blue {
		background-color: #1f5080;
		flex: 5;
	}
	.very-dark-blue {
		background-color: #12284a;
		flex: 12;
	}
	.desaturated-dark-red {
		background-color: #8f5e5e;
		flex: 3;
	}
	.dark-red {
		background-color: #75141f;
		flex: 20;
		margin: 0 0 0 3px;
	}
}
</style>
