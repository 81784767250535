<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
	components: { VueSlickCarousel },
	data() {
		return {
			settings: {
				arrows: true,
				dots: true,
				infinite: true,
				speed: 500,
				slidesToShow: 3,
				slidesToScroll: 3,
				touchThreshold: 5,
				responsive: [
					{
						breakpoint: 1374,
						settings: {
							slidesToShow: 3,
							slidesToScroll: 3,
							arrows: false,
						},
					},
					{
						breakpoint: 1200,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2,
							initialSlide: 2,
							arrows: false,
						},
					},
					{
						breakpoint: 700,
						settings: {
							slidesToShow: 1,
							slidesToScroll: 1,
							arrows: false,
						},
					},
				],
			},
		};
	},
};
</script>

<template lang="pug">
  section.testimonial-section
    h2 WHAT ARE PEOPLE SAYING
    VueSlickCarousel.testimonials(v-bind="settings")
        .testimonial
            .content 
                p "It's great to see a company that has great people from the rep… clear down to the warehouse workers who are super happy to help you out. It seemed like everyone at MityLite was excited to be there."
                p "Overall, it was just a great experience. I will definitely do business with you guys again!"                
                .customer-info
                    img(src="../../images/testimonials/mx-logo.jpg" alt="MX Technologies")
                    p <strong>Kameron Bascom</strong>
                    p <strong>Head of Facilities</strong>
                    p <em>MX Technologies, Utah</em>
        .testimonial
            .content 
                p "MityLite staff were creative and ensured our ideas were incorporated into the chair design and ensured the integrity of the design was secure and worked properly. Along with other upgrades at HCC to modernize the appearance and comfort of our furnishings, the new chairs and equipment from MityLite will allow HCC to provide upgraded services for our clients and attendees."
                .customer-info
                    img(src="../../images/testimonials/hcc-logo.jpg" alt="Hawaii Convention Center")
                    p <strong>Teri Orton</strong>
                    p <strong>General Manager</strong>
                    p <em>Hawaii Convention Center</em>
        .testimonial
            .content 
                p "I was searching for high quality products that were durable, but easy to move and set up because we are constantly changing the configurations in our rooms and on the patio. We need to have furniture that's not lightweight and flimsy, but it also can't be too heavy so my staff can move it with ease. MITY had a great price and better options for the type of chairs we needed."
                .customer-info
                    img(src="../../images/testimonials/riverside-logo.jpg" alt="Riverside Country Club")
                    p <strong>Mark Davies</strong>
                    p <strong>F&B Director</strong>
                    p <em>Riverside Country Club</em>
        .testimonial
            .content
                p "MITY was very accommodating and easy to work with. The turnaround time was quicker than I thought it would be and we actually got the chairs two weeks ahead of schedule. They were able to give me a special deal to get everything that I needed with my budget. I will definitely work with MITY again as we continue to expand our facility and product needs."
                .customer-info
                    img(src="../../images/testimonials/lynnwood-logo.jpg" alt="Lynnwood Convention Center")
                    p <strong>Sara Blayne</strong>
                    p <strong>General Manager</strong>
                    p <em>Lynnwood Convention Center</em>
        .testimonial
            .content
                p "Hands down, MityLite was superior. It's actually one of the things that when we have a meeting planner tour the center, we have them sit down in the chairs. We feel like it's important; we feel like it's a competitive advantage for us."
                .customer-info
                    img(src="../../images/testimonials/uvcc-logo.jpg" alt="Utah Valley Convention Center")
                    p <strong>Danny Wheeler</strong>
                    p <strong>UVCC General Manager</strong>                        
                    p <em>Utah Valley Convention Center</em>
        .testimonial
            .content 
                p "If we're going to invest the money in new banquet chairs, we want to make sure we get something we can trust and something that will last. I really liked the design of the new chairs and I knew they would function well. I've always been happy with MityLite."
                .customer-info
                    img(src="../../images/testimonials/utsa-logo.jpg" alt="The University of Texas at San Antonio Student Union")
                    p <strong>David Marquis</strong>
                    p <strong>Assistant Director for Facilities and Operations</strong>
                    p <em>The University of Texas at San Antonio Student Union</em>
        .testimonial
            .content 
                p "We rely a great deal on MityLite equipment with our many activities that need tables, chairs, and carts. The custodians really appreciate the carts, which come in handy with moving tables and chairs constantly."
                p "I've been working with MityLite for a long time. All the tables in our facilities are MityLite."
                .customer-info
                    img(src="../../images/testimonials/dallas-community-logo.jpg" alt="City of Dallas, Park & Recreation Department")
                    p <strong>John King</strong>
                    p <strong>Service Area Manager</strong>
                    p <em>City of Dallas, Park & Recreation Department</em>
        .testimonial
            .content 
                p "MityLite provided a solution that was customized for our brand. The linenless tables look wonderful, and the chairs are extremely comfortable. Both are easy to clean and disinfect, which helps provide our clients with the peace of mind that their attendees will be in a safe environment."
                .customer-info
                    img(src="../../images/testimonials/mainsail-logo.jpg" alt="Mainsail Lodging & Development")
                    p <strong>Norwood Smith</strong>
                    p <strong>VP Sales & Marketing</strong>
                    p <em>Mainsail Lodging & Development</em>

        .testimonial
            .content 
                p "The chairs are a big upgrade from what we had before. People love the look and we get so many complements comfort-wise and aesthetic-wise."
                p "It's been great working with MityLite, and I wouldn't want to work with anybody else. MityLite is always the first place I look when I do have to purchase furniture. Always."
                .customer-info
                    img(src="../../images/testimonials/bartlett-logo.jpg" alt="Bartlett Community Center")
                    p <strong>Nicholas Serritella</strong>
                    p <strong>Scheduling Manager</strong>
                    p <em>Bartlett Community Center</em>
        .testimonial
            .content
                p "I've been very happy with the tables I purchased from MityLite. They certainly seem to withstand the daily wear and tear. You can easily over rooms into different configurations and you don't have to worry about linens. If there's a spill, you just get a rag and wipe it up. It's easy!"
                .customer-info
                    img(src="../../images/testimonials/hyatt-regency-logo.jpg" alt="Hyatt Regency Boston")
                    p <strong>Shannon Sikes</strong>
                    p <em>Hyatt Regency Boston</em>
        .testimonial
            .content
                p "I would recommend these chairs 100%! Everyone says they are so comfortable. The chairs are well designed. The carts are well designed. These are the best folding chairs I've seen in my 20 years in this industry."
                .customer-info
                    img(src="../../images/testimonials/seaport-logo.jpg" alt="Seaport Boston Hotel")
                    p <strong>Tran Truong</strong>
                    p <em>Seaport Boston Hotel</em>
        .testimonial
            .content
                p "We received the MityLite Dance Floor yesterday and it looks great. Our Executive Team was very satisfied with the product and investment we have made. This will boost more confidence for us in future while doing business with MITY. Thank you so much."
                .customer-info
                    img(src="../../images/testimonials/shangri-la-logo.jpg" alt="Shangri LA")
                    p <strong>Sunny Singh</strong>
                    p <strong>Banquet Manager</strong>
                    p <em>Shangri-La Hotel</em>
</template>

<style lang="scss">
@import "../../style/mityinc-browser.scss";

.testimonial-section {
	background-color: $white;
	padding: 75px 0 125px;

	h2 {
		font-size: 1.5em;
		margin-bottom: 50px;
	}

	.testimonials {
		margin: auto;
		max-width: 1280px;
		.testimonial {
			padding: 0 1em;
			.content {
				border: 2px $cyan-blue solid;
				border-radius: 5px;
				margin-bottom: 1em;
				min-height: 29em;
				padding: 1.5em;
				p {
					margin-bottom: 1em;
				}
				.customer-info {
					margin-top: 1em;

					img {
						float: right;
						margin-left: 1em;
					}

					p {
						margin: 0;
					}
				}
			}
		}

		.slick-arrow {
			border-radius: 5px;
			height: 70px;
			width: 70px;

			&:before {
				content: none;
			}

			&.slick-prev {
				background: $gallery-normal-background url("../../images/2020_MITYInc_Website_Pieces_Directional_Prev.png") no-repeat 45% 50%;
				left: -80px;
				&:hover {
					background-color: $gallery-hover-background;
				}
				&:active {
					background-color: $gallery-active-background;
				}
			}

			&.slick-next {
				background: $gallery-normal-background url("../../images/2020_MITYInc_Website_Pieces_Directional_Next.png") no-repeat 55% 50%;
				right: -80px;
				&:hover {
					background-color: $gallery-hover-background;
				}
				&:active {
					background-color: $gallery-active-background;
				}
			}
		}

		.slick-dots {
			bottom: -3em;
			li {
				margin: 0 4px;
				button {
					background: $gray-button;
					border-radius: 100px;
					height: 15px;
					width: 15px;
					&:before {
						content: none;
					}
					&:hover {
						background-color: $gallery-hover-background;
					}
					&:active {
						background-color: $gallery-active-background;
					}
				}

				&.slick-active {
					button {
						background: $blue;
					}
				}
			}
		}
	}
}
</style>
