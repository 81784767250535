<script>
import MityIncLogo from "./svg/MITYinc_M_Logo_White-01.vue";
import MityLiteLogo from "./svg/MityLiteLogo.vue";
import HolsagLogo from "./svg/HolsagLogo.vue";
import BertoliniLogo from "./svg/BertoliniLogo.vue";
import BrodaLogo from "./svg/BrodaLogo.vue";
import FacebookIcon from "./svg/FacebookIcon.vue";
import LinkedInIcon from "./svg/LinkedInIcon.vue";
import HubSpotForm from "./home/HubSpotForm.vue";

export default {
	components: { MityIncLogo, MityLiteLogo, HolsagLogo, BertoliniLogo, BrodaLogo, FacebookIcon, LinkedInIcon, HubSpotForm },
	data() {
		return {
			currentYear: new Date().getFullYear(),
			HubspotFormId: "8a5bba88-3501-4503-a4c9-9d947e198efc",
			isIE11: !!window.MSInputMethodContext && !!document.documentMode,
		};
	},
};
</script>

<template lang="pug">
	footer.footer-section
		.footer-mity-brand-logos
			a(href="/").logo-image
				MityIncLogo
			a(href="https://www.mitylite.com/" target="_blank").brand-image
				MityLiteLogo
			a(href="https://holsag.com/" target="_blank").brand-image
				HolsagLogo
			a(href="https://bertolinidirect.com/" target="_blank").brand-image
				BertoliniLogo
			a(href="https://brodaseating.com/" target="_blank").brand-image
				BrodaLogo
		.contact-info
			a(href="tel:1-800-909-8034") 1-800-909-8034
			span.contact-info-separator |
			a(href="mailto:info@mityinc.com") info@mityinc.com
		.social-email
			.social-links
				a(href="https://www.facebook.com/mityinc/" target="_blank")
					FacebookIcon
				a(href="https://www.linkedin.com/company/mity-inc" target="_blank")
					LinkedInIcon
			.email(v-if="!isIE11")
				p Sign up for our emails

				HubSpotForm(:HubspotFormId="HubspotFormId")
		p.copyright Copyright &#169; {{currentYear}} MITY. All rights reserved

</template>

<style lang="scss">
@import "../style/mityinc-browser.scss";
footer.footer-section {
	background-color: $mity-blue;
	color: $white;
	padding: 2em 1em;
	.footer-mity-brand-logos {
		display: flex;
		margin: 0 auto;
		max-width: 72em;
		svg {
			fill: $white;
			height: 45px;
			margin: 0 1.3em;
			width: 11em;
			&:hover {
				fill: $blue;
			}
		}
		.logo-image {
			margin: 0 auto;

			svg {
				height: auto;
				width: 5em;
			}
		}
		.brand-image {
			display: none;
		}
	}
	.contact-info {
		align-items: center;
		display: flex;
		flex-direction: column;
		margin-bottom: 1em;
		a {
			color: $white;
			font-size: 1.4em;
			padding: 0.4em 0;
			&:hover {
				color: $blue;
				text-decoration: underline;
			}
		}
		.contact-info-separator {
			display: none;
		}
	}
	.social-email {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		.social-links {
			align-self: center;
			display: flex;
			order: 2;
			padding: 3em 0;
			a {
				display: flex;
				svg {
					fill: #fff;
					margin: 0 1.3em;
					width: 2.5em;
					&:hover {
						fill: $blue;
					}
				}
			}
		}
		.email {
			order: 1;
			text-align: center;
			p {
				color: $white;
				padding: 0.3em 0;
			}
		}
	}
	.copyright {
		color: $white;
		font-size: 0.8em;
		margin: auto;
		max-width: 75em;
		text-align: center;
	}
}
@media (min-width: 800px) {
	footer.footer-section {
		.footer-mity-brand-logos {
			align-items: center;
			justify-content: space-between;
			svg {
				width: 7em;
			}
			.logo-image {
				display: none;
			}
			.brand-image {
				display: block;
			}
		}
		.contact-info {
			flex-direction: row;
			justify-content: center;
			margin-top: 2em;
			a {
				color: $white;
				font-size: 1.3em;
			}
			.contact-info-separator {
				display: block;
				margin: 0 2em;
			}
		}
		.social-email {
			flex-direction: row;
			justify-content: space-around;
			margin: 35px 0;
			.social-links {
				order: 1;
				padding: 0;
			}
			.email {
				order: 2;
				text-align: left;
			}
		}
	}
}
@media (min-width: 1024px) {
	footer.footer-section {
		.footer-mity-brand-logos {
			svg {
				width: 10em;
			}
		}
	}
}
</style>
