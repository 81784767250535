import Home from "../views/Home.vue";
import Damage from "../views/Damage.vue";
import NotFound from "../views/NotFound.vue";

const routes = [
	{
		path: "/",
		component: Home,
	},
	{
		path: "/damage",
		component: Damage,
	},
	{
		path: "*",
		component: NotFound,
	},
];

export default routes;
