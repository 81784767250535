import "es6-promise/auto";

import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import routes from "./scripts/router.js";
import VueMeta from "vue-meta";

import ScrollAnimation from "./directives/scrollAnimation";

Vue.directive("scrollanimation", ScrollAnimation);
Vue.use(VueRouter);
Vue.use(VueMeta);

const router = new VueRouter({ mode: "history", routes });

new Vue({
	router,
	render: (h) => h(App),
}).$mount("#mityinc-app");
