var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 150 150"
      }
    },
    [
      _c("g", [
        _c("path", {
          staticClass: "st0",
          attrs: {
            d:
              "M109.4,26.8v37.3l-18.2,19l-19,19.9L35,64.2v78.5c-0.5-0.3-6-3.6-6.6-3.8c-11.1-6.4-20.7-15.2-28.2-25.7V27.4C1.7,19.9,8.3,9.1,13,4.8l59.2,59.3L109.4,26.8z"
          }
        }),
        _c("polygon", {
          staticClass: "st1",
          attrs: { points: "144.2,35.5 144.2,119.7 115.4,119.7 115.4,66 " }
        }),
        _c("path", {
          staticClass: "st1",
          attrs: {
            d:
              "M144.2,27.3l-28.8,30.5V23.1l16-16.1C136.2,9.1,142.6,21.5,144.2,27.3z"
          }
        }),
        _c("g", [
          _c("path", {
            staticClass: "st0",
            attrs: {
              d:
                "M42.5,126.3h3.9l4.3,6.9l4.3-6.9h3.9v16.3h-3.6V132l-4.6,7h-0.1l-4.6-6.9v10.6h-3.5V126.3z"
            }
          }),
          _c("path", {
            staticClass: "st0",
            attrs: { d: "M62,126.3h3.6v16.3H62V126.3z" }
          }),
          _c("path", {
            staticClass: "st0",
            attrs: { d: "M73.5,129.6h-5v-3.3H82v3.3h-5v13h-3.6V129.6z" }
          }),
          _c("path", {
            staticClass: "st0",
            attrs: {
              d:
                "M89.4,136.2l-6.3-9.9h4.2l3.9,6.6l4-6.6h4.1l-6.3,9.8v6.5h-3.6V136.2z"
            }
          }),
          _c("path", {
            staticClass: "st0",
            attrs: { d: "M106.8,126.3h3.6v16.3h-3.6V126.3z" }
          }),
          _c("path", {
            staticClass: "st0",
            attrs: {
              d:
                "M113.2,130.2h3.5v1.8c0.8-1.1,1.9-2,3.7-2c2.7,0,4.2,1.8,4.2,4.6v8.1h-3.5v-7c0-1.7-0.8-2.5-2.1-2.5c-1.4,0-2.2,0.9-2.2,2.5v7h-3.5V130.2z"
            }
          }),
          _c("path", {
            staticClass: "st0",
            attrs: {
              d:
                "M126.6,136.5L126.6,136.5c0-3.6,2.7-6.6,6.6-6.6c2.4,0,3.8,0.8,5,2.1l-2.2,2.3c-0.8-0.8-1.6-1.4-2.8-1.4c-1.8,0-3,1.6-3,3.4v0c0,1.9,1.2,3.5,3.2,3.5c1.2,0,2-0.5,2.9-1.3l2.1,2.1c-1.2,1.3-2.6,2.3-5.1,2.3C129.4,143,126.6,140.1,126.6,136.5z"
            }
          }),
          _c("path", {
            staticClass: "st0",
            attrs: { d: "M140.4,138.9h3.8v3.7h-3.8V138.9z" }
          })
        ]),
        _c("path", {
          staticClass: "st2",
          attrs: {
            d:
              "M149.4,126c0,2.1-1.6,3.7-3.7,3.7c-2.1,0-3.8-1.6-3.8-3.7c0-2,1.7-3.6,3.8-3.6C147.7,122.3,149.4,123.9,149.4,126z M142.8,126c0,1.6,1.2,2.9,2.8,2.9c1.6,0,2.8-1.3,2.8-2.9c0-1.6-1.2-2.9-2.8-2.9C144,123.1,142.8,124.4,142.8,126z M145.1,127.9h-0.8v-3.6c0.3-0.1,0.8-0.1,1.4-0.1c0.7,0,1,0.1,1.3,0.3c0.2,0.2,0.4,0.4,0.4,0.8c0,0.4-0.3,0.7-0.8,0.8v0c0.4,0.1,0.6,0.4,0.7,0.9c0.1,0.6,0.2,0.8,0.3,0.9h-0.9c-0.1-0.1-0.2-0.5-0.3-0.9c-0.1-0.4-0.3-0.6-0.8-0.6h-0.4V127.9z M145.1,125.8h0.4c0.5,0,0.8-0.2,0.8-0.5c0-0.3-0.2-0.6-0.8-0.6c-0.2,0-0.4,0-0.5,0V125.8z"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ea1c69cc", { render: render, staticRenderFns: staticRenderFns })
  }
}