var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      { staticClass: "about-mity", attrs: { id: "about" } },
      [
        _c("div", { staticClass: "about-mity-content" }, [
          _c("h2", [_vm._v("Established in 1987")]),
          _c("h3", [_vm._v("Headquartered in Orem, Utah · USA")]),
          _c("p", [
            _vm._v(
              "MITY Incorporated manages product brands that serve the commercial marketplace. We’ve served customers in the hospitality, government, education, worship, and restaurant industries (to name a few) for more than 30 years. And we’re just getting started!"
            )
          ]),
          _c("p", [
            _vm._v(
              "With offices and factories in Utah, Missouri, and Ontario, Canada, we provide high-quality products with short lead times. We take pride in investing in the communities our employees call home."
            )
          ])
        ]),
        _c("div", { staticClass: "about-mity-images" }, [
          _c("div", { staticClass: "about-mity-left" }, [
            _c("img", {
              attrs: {
                src: require("../../images/established/2020_MITYInc_Website_Pieces_History_Image_01.jpg"),
                alt: "original founders standing and sitting on table"
              }
            }),
            _c("img", {
              attrs: {
                src: require("../../images/established/2020_MITYInc_Website_Pieces_History_Image_02.jpg"),
                alt: "employee building a table"
              }
            })
          ]),
          _c("div", { staticClass: "about-mity-right" }, [
            _c("img", {
              attrs: {
                src: require("../../images/established/2020_MITYInc_Website_Pieces_History_Image_03.jpg"),
                alt:
                  "truck full of products with two men standing behind shaking hands"
              }
            }),
            _c("img", {
              attrs: {
                src: require("../../images/established/2020_MITYInc_Website_Pieces_History_Image_04.jpg"),
                alt: "young girl holding up end of table above her head"
              }
            })
          ])
        ])
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-23c071cd", { render: render, staticRenderFns: staticRenderFns })
  }
}