<script>
export default {};
</script>

<template lang="pug">
    section.product-pictures
        img(src="../../images/product_images/product_1.jpg" alt="product image")
        img(src="../../images/product_images/product_2.jpg" alt="product image")
        img(src="../../images/product_images/product_3.jpg" alt="product image")
        img(src="../../images/product_images/product_4.jpg" alt="product image")
        img(src="../../images/product_images/product_5.jpg" alt="product image")
</template>

<style lang="scss">
.product-pictures {
	display: none;
}
@media (min-width: 1450px) {
	.product-pictures {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 0 auto;
		max-width: 1440px;
		padding-bottom: 55px;
		img {
			height: 190px;
		}
	}
}
</style>
