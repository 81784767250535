var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "mity-brand-logos" }, [
    _c(
      "a",
      { attrs: { href: "https://www.mitylite.com/", target: "_blank" } },
      [
        _c(
          "svg",
          {
            staticClass: "mitylite",
            attrs: {
              id: "Layer_1",
              "data-name": "Layer 1",
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 496.07 76.31"
            }
          },
          [
            _c("polygon", {
              staticClass: "cls-1",
              attrs: {
                points:
                  "63.99 1.62 41.78 29.13 19.66 1.62 0 1.62 0 74.95 21.07 74.95 21.07 32.47 40.69 57.07 42.4 57.07 61.97 32.47 61.97 74.95 83.67 74.95 83.67 1.62 63.99 1.62"
              }
            }),
            _c("rect", {
              staticClass: "cls-1",
              attrs: { x: "95.66", y: "1.62", width: "21.7", height: "73.33" }
            }),
            _c("polygon", {
              staticClass: "cls-1",
              attrs: {
                points:
                  "202.91 19.45 176.55 19.45 176.55 74.95 154.91 74.95 154.91 19.45 128.53 19.45 128.53 1.62 202.91 1.62 202.91 19.45"
              }
            }),
            _c("polygon", {
              staticClass: "cls-1",
              attrs: {
                points:
                  "285.75 1.62 257.57 41.36 257.57 74.95 235.87 74.95 235.87 41.77 207.17 1.62 229.69 1.62 246.21 24.69 262.76 1.62 285.75 1.62"
              }
            }),
            _c("polygon", {
              staticClass: "cls-2",
              attrs: {
                points:
                  "294.37 1.04 304.75 1.04 304.75 65.31 337.39 65.31 337.39 74.95 294.37 74.95 294.37 1.04"
              }
            }),
            _c("rect", {
              staticClass: "cls-2",
              attrs: { x: "346.58", y: "1.04", width: "10.38", height: "73.91" }
            }),
            _c("polygon", {
              staticClass: "cls-2",
              attrs: {
                points:
                  "366.03 0.93 426.6 0.93 426.6 10.35 401.33 10.35 401.33 74.95 390.89 74.95 390.89 10.35 366.03 10.35 366.03 0.93"
              }
            }),
            _c("polygon", {
              staticClass: "cls-2",
              attrs: {
                points:
                  "436.41 1.04 477.52 1.04 477.52 10.46 446.79 10.46 446.79 32.99 476.48 32.99 476.48 42.47 446.79 42.47 446.79 65.43 478.51 65.43 478.51 74.84 436.41 74.84 436.41 1.04"
              }
            }),
            _c("path", {
              staticClass: "cls-2",
              attrs: {
                d:
                  "M488.56,5.51c.75,0,1.19-.36,1.19-.94v0c0-.6-.44-.93-1.19-.93h-1.5v1.9Zm-2.64-2.94h2.69a2.55,2.55,0,0,1,1.83.64,1.74,1.74,0,0,1,.49,1.27v0a1.79,1.79,0,0,1-1.4,1.81l1.62,2.28h-1.37l-1.45-2.08h-1.27V8.6h-1.14Zm7.47,3.18v0a5.15,5.15,0,1,0-10.3,0v0a5.15,5.15,0,1,0,10.3,0m-10.9,0v0a5.76,5.76,0,0,1,11.51,0v0a5.76,5.76,0,0,1-11.51,0"
              }
            })
          ]
        )
      ]
    ),
    _c("a", { attrs: { href: "https://holsag.com/", target: "_blank" } }, [
      _c(
        "svg",
        {
          staticClass: "holsag",
          attrs: {
            id: "Layer_1",
            "data-name": "Layer 1",
            xmlns: "http://www.w3.org/2000/svg",
            viewBox: "0 0 221.68 76.31"
          }
        },
        [
          _c("path", {
            staticClass: "cls-1",
            attrs: {
              d:
                "M217.25,21.63c.37,0,.58-.18.58-.46h0c0-.3-.21-.46-.58-.46h-.73v.93ZM216,20.19h1.31a1.29,1.29,0,0,1,.9.31.89.89,0,0,1,.23.63h0a.85.85,0,0,1-.68.88l.8,1.11h-.67l-.71-1h-.62v1H216Zm3.65,1.56v0a2.48,2.48,0,0,0-2.51-2.51,2.51,2.51,0,0,0-2.52,2.53h0a2.52,2.52,0,0,0,5,0m-5.32,0h0a2.81,2.81,0,0,1,2.81-2.81,2.78,2.78,0,0,1,2.8,2.79v0a2.82,2.82,0,0,1-2.81,2.81,2.79,2.79,0,0,1-2.8-2.8"
            }
          }),
          _c("path", {
            staticClass: "cls-1",
            attrs: {
              d:
                "M.82.49H8V25.25h.16a13.39,13.39,0,0,1,5.17-5.09,14.83,14.83,0,0,1,7.38-2.05c5.32,0,13.85,3.28,13.85,17V58.69H27.37V35.9c0-6.39-2.37-11.8-9.18-11.8a10.36,10.36,0,0,0-9.67,7.21A8.72,8.72,0,0,0,8,34.75V58.69H.82Z"
            }
          }),
          _c("path", {
            staticClass: "cls-2",
            attrs: {
              d:
                "M48.69,38.93c0,8.69,5,15.25,12,15.25s12.06-6.47,12.06-15.41c0-6.72-3.37-15.25-11.89-15.25S48.69,31.39,48.69,38.93m31.47-.41C80.16,53.2,70,59.59,60.41,59.59c-10.74,0-19-7.87-19-20.41,0-13.28,8.69-21.07,19.67-21.07,11.4,0,19.1,8.28,19.1,20.41"
            }
          }),
          _c("rect", {
            staticClass: "cls-3",
            attrs: { x: "86.65", y: "0.49", width: "7.21", height: "58.2" }
          }),
          _c("path", {
            staticClass: "cls-1",
            attrs: {
              d:
                "M104.6,51.31a18.93,18.93,0,0,0,9.51,2.87c5.25,0,7.71-2.62,7.71-5.9,0-3.44-2.06-5.33-7.38-7.3C107.31,38.44,104,34.51,104,29.75c0-6.39,5.16-11.64,13.68-11.64a19.82,19.82,0,0,1,9.76,2.46l-1.81,5.25a15.33,15.33,0,0,0-8.11-2.3c-4.26,0-6.64,2.46-6.64,5.41,0,3.28,2.38,4.76,7.54,6.73,6.88,2.62,10.41,6.06,10.41,12,0,7-5.41,11.89-14.84,11.89a22.65,22.65,0,0,1-11.15-2.71Z"
            }
          }),
          _c("path", {
            staticClass: "cls-1",
            attrs: {
              d:
                "M158,38.44c-7.87-.16-16.81,1.23-16.81,8.94,0,4.67,3.12,6.88,6.81,6.88a9.88,9.88,0,0,0,9.59-6.64,7.62,7.62,0,0,0,.41-2.29Zm1.06,20.25-.57-5h-.25a14.75,14.75,0,0,1-12.13,5.9c-8,0-12.13-5.65-12.13-11.39,0-9.59,8.53-14.84,23.85-14.76v-.82c0-3.28-.9-9.18-9-9.18a19.8,19.8,0,0,0-10.32,2.95l-1.64-4.75a24.6,24.6,0,0,1,13-3.53C162,18.11,165,26.39,165,34.35V49.18a56.16,56.16,0,0,0,.65,9.51Z"
            }
          }),
          _c("path", {
            staticClass: "cls-1",
            attrs: {
              d:
                "M203,34.67a10.53,10.53,0,0,0-.41-3.28,10.4,10.4,0,0,0-10.09-7.62c-6.88,0-11.8,5.82-11.8,15,0,7.79,3.93,14.26,11.72,14.26a10.53,10.53,0,0,0,10-7.37A12.6,12.6,0,0,0,203,41.8ZM210.41,19c-.17,2.87-.33,6.07-.33,10.91V53c0,9.1-1.81,14.67-5.66,18.12-3.85,3.6-9.42,4.75-14.43,4.75-4.75,0-10-1.15-13.19-3.28l1.8-5.49a22.35,22.35,0,0,0,11.64,3.12c7.38,0,12.79-3.86,12.79-13.86V51.89h-.17c-2.21,3.69-6.47,6.64-12.62,6.64-9.84,0-16.89-8.37-16.89-19.35,0-13.44,8.77-21.07,17.87-21.07,6.89,0,10.66,3.61,12.38,6.89h.17l.32-6Z"
            }
          })
        ]
      )
    ]),
    _c(
      "a",
      { attrs: { href: "https://bertolinidirect.com/", target: "_blank" } },
      [
        _c(
          "svg",
          {
            staticClass: "bertolini",
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 496.07 76.31"
            }
          },
          [
            _c("path", {
              staticClass: "cls-3",
              attrs: {
                d:
                  "M469.75,71.33H459.69V1.23h10.06ZM444.16,1.23v70.1h-8.64l-46.88-54v54h-9.45V1.23h8L434.7,55.51V1.23Zm-80.38,70.1H353.69V1.23h10.09ZM313.2,1.23V62.29h31.66v9H303.13V1.23ZM225.92,36.28q0,11.63,7.77,19.23t19.49,7.6q11.81,0,19.57-7.6a25.67,25.67,0,0,0,7.77-19.12q0-11.62-7.77-19.18T253.18,9.66q-11.73,0-19.49,7.55t-7.77,19.18Zm-10.36,0A35.13,35.13,0,0,1,225.82,11.1Q236.11.41,253.18.41q17.35,0,27.54,10.64A35.41,35.41,0,0,1,290.9,36.39a35.68,35.68,0,0,1-10.08,25Q270.76,72.27,253,72.26q-17.15,0-27.28-10.79a35.5,35.5,0,0,1-10.12-25.08Zm.3-35.05v9H191.6V71.33H181.52v-61H157.27v-9ZM120.67,9.87v22h6.48q8,.11,10.89-3.35a11.72,11.72,0,0,0,2.88-7.75v-.21a10.24,10.24,0,0,0-3.29-7.83q-3.19-2.86-10.48-2.86ZM110.6,1.23h17.68q10.68.1,16.85,5.35T151.3,21a18.09,18.09,0,0,1-3,10.48,18.57,18.57,0,0,1-8.68,6.69A27.82,27.82,0,0,1,145,42.75a54.41,54.41,0,0,1,4.68,6.07c.88,1.31,1.8,2.7,2.72,4.16s1.87,3,2.83,4.58c.4.69.8,1.33,1.19,2s.76,1.19,1.17,1.74c.07.14.16.29.25.46a2.84,2.84,0,0,0,.37.47c.81,1.23,1.75,2.55,2.77,4l3.81,5.19h-12L143.5,56.94q-5.87-9-9.46-12.17a13.41,13.41,0,0,0-6.79-3.55c-.42-.07-.81-.13-1.19-.16s-.76,0-1.18,0h-4.21V71.33H110.6Zm-11.82,0v9H69.07V31.45H97.75V40.5H69.07V62.29H99.7v9H59V1.23Zm-87.59,9v21h6.69q7.5.09,11.15-2.69t3.65-8.47a10,10,0,0,0-2.63-6.87c-1.74-2-4.95-2.94-9.6-2.94Zm0,30v22H23.84q8,.1,11.25-2.89a10.55,10.55,0,0,0,3.65-6.63,3,3,0,0,0,.11-.76v-.76a9.65,9.65,0,0,0-3.8-7.91q-3.81-3.06-11.73-3.05Zm-10.07,31V1.23H21.47q11.1.1,16.25,5.5A17.79,17.79,0,0,1,43.06,18.6v.62a15.18,15.18,0,0,1-2.62,9.1,18.18,18.18,0,0,1-8.07,5.91v.1q7.92,1.55,12.38,6.42a17.05,17.05,0,0,1,4.48,12c0,.54,0,1.09,0,1.63A9.43,9.43,0,0,1,48.92,56q-1.14,5.74-6.69,10.54T25.17,71.33Z"
              }
            }),
            _c("polygon", {
              staticClass: "cls-3",
              attrs: {
                points:
                  "477.6 0.98 477.6 1.92 480.58 1.92 480.58 9.88 481.59 9.88 481.59 1.92 484.57 1.92 484.57 0.98 477.6 0.98"
              }
            }),
            _c("polygon", {
              staticClass: "cls-3",
              attrs: {
                points:
                  "486.45 0.99 487.46 0.99 490.7 5.84 493.94 0.99 494.95 0.99 494.95 9.88 493.95 9.88 493.95 2.68 490.72 7.44 490.66 7.44 487.43 2.69 487.43 9.88 486.45 9.88 486.45 0.99"
              }
            })
          ]
        )
      ]
    ),
    _c(
      "a",
      { attrs: { href: "https://brodaseating.com/", target: "_blank" } },
      [
        _c(
          "svg",
          {
            staticClass: "broda",
            attrs: {
              id: "Layer_1",
              "data-name": "Layer 1",
              xmlns: "http://www.w3.org/2000/svg",
              viewBox: "0 0 325.91 73.49"
            }
          },
          [
            _c("path", {
              staticClass: "cls-1",
              attrs: {
                d:
                  "M23.87,63.08H9.67V38.91H24c9.37,0,15.32,4.13,15.32,12.38,0,9.83-9.57,11.79-15.42,11.79M9.67,10H24.88c7.76,0,11.28,4.72,11.28,10.22,0,7.56-5.94,10.42-13.3,10.42H9.67ZM34.15,34.68v-.19c7.25-2.36,12.29-7.37,12.29-15.33,0-11.79-9.27-17.39-21.66-17.39H0V71.34H24.48c13.9,0,25.08-6.88,25.08-19.36,0-9.34-6.85-16.7-15.41-17.3"
              }
            }),
            _c("path", {
              staticClass: "cls-1",
              attrs: {
                d:
                  "M65.23,32.43V10H77.92c7.65,0,15.31,1.76,15.31,11.19S85.57,32.43,77.92,32.43ZM103.5,21.22c0-8.74-4.63-19.45-22.86-19.45H55.56V71.34h9.67V40.68H76L94.13,71.34h12.09L86,39.6c10.78-1.18,17.52-9.54,17.52-18.38"
              }
            }),
            _c("path", {
              staticClass: "cls-1",
              attrs: {
                d:
                  "M143.91,64.26c-16.32,0-26.9-12.48-26.9-27.71s10.58-27.7,26.9-27.7,26.9,12.47,26.9,27.7-10.58,27.71-26.9,27.71m0-64.26c-21.76,0-37.17,15.92-37.17,36.55S122.15,73.1,143.91,73.1s37.17-15.91,37.17-36.55S165.67,0,143.91,0"
              }
            }),
            _c("path", {
              staticClass: "cls-1",
              attrs: {
                d:
                  "M208.27,62.49H196.08V10.62h14.51c23.17,0,27.89,17.09,27.89,25.93,0,17.2-13.9,25.94-30.21,25.94m3-60.72H186.41V71.33h23.27c25.48,0,39.08-17,39.08-34.78,0-12.87-7.35-34.78-37.47-34.78"
              }
            }),
            _c("path", {
              staticClass: "cls-1",
              attrs: {
                d:
                  "M264.6,45.89l.2-29.34h.2l24.93,29.34Zm1-44.12H254.17l0,69.56H265l-.3-17.19H297l14.5,17.19h14.42Z"
              }
            }),
            _c("path", {
              staticClass: "cls-1",
              attrs: {
                d:
                  "M310.32,5.28a2.64,2.64,0,0,0-1.22-.21h-.87v2h.91a2.94,2.94,0,0,0,1-.12.86.86,0,0,0,.6-.87.82.82,0,0,0-.39-.81m-1.08-.9a4.44,4.44,0,0,1,1.58.2A1.38,1.38,0,0,1,311.71,6a1.21,1.21,0,0,1-.56,1.11,2.59,2.59,0,0,1-.84.27,1.39,1.39,0,0,1,1,.54,1.59,1.59,0,0,1,.31.87V9.2c0,.13,0,.27,0,.42a.85.85,0,0,0,.06.29l0,.07h-1l0-.06,0-.06,0-.18V9.24A1.37,1.37,0,0,0,310.15,8,2.55,2.55,0,0,0,309,7.78h-.79V10h-1V4.38Zm-3.09-.3a4.17,4.17,0,0,0-1.33,3.12,4.24,4.24,0,0,0,1.32,3.13,4.61,4.61,0,0,0,6.42,0,4.24,4.24,0,0,0,1.32-3.13,4.2,4.2,0,0,0-1.32-3.12,4.63,4.63,0,0,0-6.41,0m6.91,6.74a5.32,5.32,0,0,1-7.43,0,4.89,4.89,0,0,1-1.52-3.63,4.87,4.87,0,0,1,1.53-3.61,5.36,5.36,0,0,1,7.42,0,4.88,4.88,0,0,1,1.54,3.61,4.91,4.91,0,0,1-1.54,3.63"
              }
            })
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-098ad152", { render: render, staticRenderFns: staticRenderFns })
  }
}