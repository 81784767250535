var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "work-with-us", attrs: { id: "careers" } },
    [
      _c("h2", [_vm._v("WORK WITH US")]),
      _c("p", [
        _vm._v(
          "MityLite is not your typical commercial furniture manufacturer. We are a team of innovators, thought leaders, and creative problem solvers obsesed with serving our business partners so they can be successful."
        )
      ]),
      _c("p", [
        _vm._v(
          "We employ hundreds of American and Canadian workers with a variety of skill sets and interests. If you're ready to join a dynamic team where your contribution will matter, then we're excited to work with you."
        )
      ]),
      _c("div", { staticClass: "work-with-us-images" }, [
        _c("img", {
          attrs: {
            src: require("../../images/work_with_us/2020_MITYInc_Website_Pieces_CornHole_Image.jpg"),
            alt: "employees playing a game"
          }
        }),
        _c("img", {
          attrs: {
            src: require("../../images/work_with_us/2020_MITYInc_Website_Pieces_PancakeBreakfast_Image.jpg"),
            alt: "employees gathered outside eating pancakes for breakfast"
          }
        }),
        _c("div", [
          _c("h3", [_vm._v("What we bring to the table.")]),
          _vm._m(0),
          _c(
            "a",
            {
              attrs: {
                href:
                  "https://www.paycomonline.net/v4/ats/web.php/jobs?clientkey=9CAFE0B9E9A63840A7A5BBF7C294E6B6",
                target: "_blank"
              }
            },
            [_c("VisitIcon"), _c("p", [_vm._v("View Job Openings")])],
            1
          )
        ]),
        _c("img", {
          attrs: {
            src: require("../../images/work_with_us/2020_MITYInc_Website_Pieces_UpholsteryTeam_Image.jpg"),
            alt: "upholstry team posing behind two chairs"
          }
        }),
        _c("img", {
          attrs: {
            src: require("../../images/work_with_us/2020_MITYInc_Website_Pieces_Massage_Image.jpg"),
            alt: "employees receiving professional massages"
          }
        }),
        _c("img", {
          attrs: {
            src: require("../../images/work_with_us/2020_MITYInc_Website_Pieces_SalesGala_Image.jpg"),
            alt: "two men in suits shaking hands"
          }
        }),
        _c("img", {
          attrs: {
            src: require("../../images/work_with_us/2020_MITYInc_Website_Pieces_Sales_Image.jpg"),
            alt: "Sales manager helping sales employee at a computer"
          }
        }),
        _c("img", {
          attrs: {
            src: require("../../images/work_with_us/2020_MITYInc_Website_Pieces_Manufacturing_Image.jpg"),
            alt: "women and man standing in front of papers talking"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "Medical, dental, & vision insurance options including an HSA matching program"
        )
      ]),
      _c("li", [_vm._v("Retirement program with a 401k matching program")]),
      _c("li", [_vm._v("Employee life insurance")]),
      _c("li", [
        _vm._v(
          "Free Employee Assistance Program for mental & emotional wellness"
        )
      ]),
      _c("li", [
        _vm._v(
          "Team building activities & parties including pancake breakfasts & corn-hole competitions"
        )
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1caa6365", { render: render, staticRenderFns: staticRenderFns })
  }
}