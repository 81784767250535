<script>
export default {};
</script>

<template lang="pug">
    section.what-we-do
        h2 WHAT WE DO
        .what-we-do-items
            .what-we-do-item.manufacturing
                img(src="../../images/what_we_do/2020_MITYInc_Website_Pieces_Manufacturing_Icon.png" alt="hand holding wrench icon")
                .what-we-do-item-content
                    h3 Manufacturing
                    p We manage high-quality product brands that manufacture products that provide the best customer value.
            .what-we-do-item.design
                img(src="../../images/what_we_do/2020_MITYInc_Website_Pieces_Design_Icon.png" alt="light bulb combined with gear icon")
                .what-we-do-item-content
                    h3 Design & Customization
                    p Our in-house teams work with designers to find and specify the perfect products to complement any decor.
            .what-we-do-item.sourcing
                img(src="../../images/what_we_do/2020_MITYInc_Website_Pieces_Sourcing_Icon.png" alt="magnify glass over globe icon")
                .what-we-do-item-content
                    h3 Sourcing
                    p Our brands keep costs low by working hard to find the most cost-effective sourcing partners for our customers.
            .what-we-do-item.value
                img(src="../../images/what_we_do/2020_MITYInc_Website_Pieces_Value_Icon.png" alt="coin going into piggy bank icon")
                .what-we-do-item-content
                    h3 Value
                    p We stand behind the products built by our brands by offering industry-leading warranties.
            .what-we-do-item.service
                img(src="../../images/what_we_do/2020_MITYInc_Website_Pieces_Service_Icon.png" alt="headset with a shape of person inside bubble icon")
                .what-we-do-item-content
                    h3 Service
                    p Our customer service and sales teams are located in the United States and Canada to provide the best domestic service.
            .what-we-do-item.inovative
                img(src="../../images/what_we_do/2020_MITYInc_Website_Pieces_Innovation_Icon.png" alt="two hands shaking icon")
                .what-we-do-item-content
                    h3 Innovative Solutions
                    p Our brands work with customers to design new products that fit individual needs and changing industries.

</template>

<style lang="scss">
@import "../../style/mityinc-browser.scss";

.what-we-do {
	padding: 1em;
	text-align: center;
	h2 {
		font-size: 1.5em;
		padding: 0.67em 0;
	}
	.what-we-do-items {
		text-align: center;
		display: flex;
		flex-wrap: wrap;
		margin: auto;
		max-width: 67.5em;
		.what-we-do-item {
			display: flex;
			flex-direction: column;
			max-width: 21.875em;
			margin: auto;
			padding: 2em 0;
			img {
				height: 82px;
				padding: 1em 2em;
				margin: auto;
				width: auto;
			}
			.what-we-do-item-content {
				h3 {
					color: $mity-blue;
					font-size: 1.3em;
					font-weight: bold;
					padding-bottom: 0.77em;
				}
				p {
					font-size: 1em;
				}
			}
			&.manufacturing {
				order: 1;
			}
			&.sourcing {
				order: 2;
			}
			&.service {
				order: 3;
			}
			&.design {
				order: 4;
			}
			&.value {
				order: 5;
			}
			&.inovative {
				order: 6;
			}
		}
	}
}
@media (min-width: 1020px) {
	.what-we-do {
		.what-we-do-items {
			text-align: left;
			justify-content: space-around;
			.what-we-do-item {
				flex-direction: row;
				max-width: 30em;
				img {
					height: 82px;
					padding: 0;
				}
				.what-we-do-item-content {
					padding-left: 2em;
					h3 {
						text-align: left;
					}
				}
				&.manufacturing {
					order: 1;
				}
				&.design {
					order: 2;
				}
				&.sourcing {
					order: 3;
				}
				&.value {
					order: 4;
				}
				&.service {
					order: 5;
				}
				&.inovative {
					order: 6;
				}
			}
		}
	}
}
</style>
