var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 496.07 76.31"
      }
    },
    [
      _c("polygon", {
        staticClass: "cls-1",
        attrs: {
          points:
            "63.99 1.79 41.78 29.31 19.66 1.79 0 1.79 0 75.12 21.07 75.12 21.07 32.65 40.69 57.24 42.4 57.24 61.97 32.65 61.97 75.12 83.67 75.12 83.67 1.79 63.99 1.79"
        }
      }),
      _c("rect", {
        staticClass: "cls-1",
        attrs: { x: "95.66", y: "1.8", width: "21.7", height: "73.33" }
      }),
      _c("polygon", {
        staticClass: "cls-1",
        attrs: {
          points:
            "202.91 19.63 176.55 19.63 176.55 75.13 154.91 75.13 154.91 19.63 128.53 19.63 128.53 1.79 202.91 1.79 202.91 19.63"
        }
      }),
      _c("polygon", {
        staticClass: "cls-1",
        attrs: {
          points:
            "285.75 1.8 257.57 41.53 257.57 75.12 235.87 75.12 235.87 41.95 207.17 1.8 229.69 1.8 246.21 24.86 262.76 1.8 285.75 1.8"
        }
      }),
      _c("polygon", {
        staticClass: "cls-1",
        attrs: {
          points:
            "294.37 1.21 304.75 1.21 304.75 65.49 337.39 65.49 337.39 75.12 294.37 75.12 294.37 1.21"
        }
      }),
      _c("rect", {
        staticClass: "cls-1",
        attrs: { x: "346.58", y: "1.21", width: "10.38", height: "73.91" }
      }),
      _c("polygon", {
        staticClass: "cls-1",
        attrs: {
          points:
            "366.03 1.11 426.6 1.11 426.6 10.53 401.33 10.53 401.33 75.12 390.89 75.12 390.89 10.53 366.03 10.53 366.03 1.11"
        }
      }),
      _c("polygon", {
        staticClass: "cls-1",
        attrs: {
          points:
            "436.41 1.21 477.52 1.21 477.52 10.63 446.79 10.63 446.79 33.17 476.48 33.17 476.48 42.64 446.79 42.64 446.79 65.6 478.51 65.6 478.51 75.02 436.41 75.02 436.41 1.21"
        }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M488.56,5.69c.75,0,1.19-.37,1.19-.94v0c0-.6-.44-.94-1.19-.94h-1.5V5.69Zm-2.64-2.94h2.69a2.59,2.59,0,0,1,1.83.63,1.78,1.78,0,0,1,.49,1.28v0a1.8,1.8,0,0,1-1.4,1.81l1.62,2.27h-1.37l-1.45-2.08h-1.27V8.77h-1.14Zm7.47,3.18v0a5.15,5.15,0,1,0-10.3,0V6a5.15,5.15,0,1,0,10.3,0m-10.9,0v0A5.76,5.76,0,0,1,488.26.18,5.7,5.7,0,0,1,494,5.9v0a5.76,5.76,0,0,1-11.51,0"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-66df7f35", { render: render, staticRenderFns: staticRenderFns })
  }
}