var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "our-partners", attrs: { id: "partners" } },
    [
      _c("h2", [_vm._v("OUR PARTNERS")]),
      _c(
        "VueSlickCarousel",
        _vm._b(
          { staticClass: "partner-logos" },
          "VueSlickCarousel",
          _vm.settings,
          false
        ),
        [
          _c("div", { staticClass: "logo logo-7" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_Marriott_Logo.jpg"),
                alt: "marriott logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-7" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_Hilton_Logo.jpg"),
                alt: "hilton logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-7" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_Hyatt_Logo.jpg"),
                alt: "hyatt logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-7" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_BuyBoard_Logo.jpg"),
                alt: "buyboard logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-7" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_IAVM_Logo.jpg"),
                alt: "iavm logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-7" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_HardRockHotel_Logo.jpg"),
                alt: "hard rock logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-7" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_HarvardU_Logo.jpg"),
                alt: "harvard logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-8" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_DirectSupply_Logo.jpg"),
                alt: "direct supply logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-8" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_Aramark_Logo.jpg"),
                alt: "aramark logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-8" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_HomeDepot_Logo.jpg"),
                alt: "home depot logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-8" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_WaltDisneyCompany_Logo.jpg"),
                alt: "walt disney logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-8" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_LVRaiders_Logo.jpg"),
                alt: "raiders logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-8" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_EI_Logo.jpg"),
                alt: "ei logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-8" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_ACUI_Logo.jpg"),
                alt: "acui logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-8" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_COOP_Logo.jpg"),
                alt: "coop logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-7" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_GSA_Logo.jpg"),
                alt: "gsa logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-7" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_TXMAS_Logo.jpg"),
                alt: "txmas logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-7" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_ChoiceHotels_Logo.jpg"),
                alt: "choice hotels logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-7" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_CMAS_Logo.jpg"),
                alt: "cmas logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-7" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_Spectra_Logo.jpg"),
                alt: "spectra logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-7" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_ClubProcure_Logo.jpg"),
                alt: "club procure logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-7" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_CORD_Logo.jpg"),
                alt: "cord logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-7" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_BenjaminWest_Logo.jpg"),
                alt: "benjamin west logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-7" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_CaesarsEntertainment_Logo.jpg"),
                alt: "caesars entertainment logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-7" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_EdDon_Logo.jpg"),
                alt: "ed don logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-7" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_ASM_Logo.jpg"),
                alt: "asm logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-7" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_Motion_Logo.jpg"),
                alt: "motion logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-7" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_Advacare_Logo.jpg"),
                alt: "adva care logo"
              }
            })
          ]),
          _c("div", { staticClass: "logo logo-7" }, [
            _c("img", {
              staticClass: "partner-logo",
              attrs: {
                src: require("../../images/our_partners/2020_MITYInc_Website_Pieces_PAPC_Logo.jpg"),
                alt: "papc logo"
              }
            })
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4c627f57", { render: render, staticRenderFns: staticRenderFns })
  }
}