<script>
export default {};
</script>

<template lang="pug">
    section#about.about-mity
        .about-mity-content
            h2 Established in 1987
            h3 Headquartered in Orem, Utah · USA
            p MITY Incorporated manages product brands that serve the commercial marketplace. We’ve served customers in the hospitality, government, education, worship, and restaurant industries (to name a few) for more than 30 years. And we’re just getting started!
            p With offices and factories in Utah, Missouri, and Ontario, Canada, we provide high-quality products with short lead times. We take pride in investing in the communities our employees call home.
        .about-mity-images
            .about-mity-left
                img(src="../../images/established/2020_MITYInc_Website_Pieces_History_Image_01.jpg" alt="original founders standing and sitting on table")
                img(src="../../images/established/2020_MITYInc_Website_Pieces_History_Image_02.jpg" alt="employee building a table")
            .about-mity-right
                img(src="../../images/established/2020_MITYInc_Website_Pieces_History_Image_03.jpg" alt="truck full of products with two men standing behind shaking hands")
                img(src="../../images/established/2020_MITYInc_Website_Pieces_History_Image_04.jpg" alt="young girl holding up end of table above her head")
</template>

<style lang="scss">
@import "../../style/mityinc-browser.scss";
.about-mity {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	max-width: 1440px;
	img {
		width: 100%;
	}
	.about-mity-content {
		background-color: $light-gray-blue;
		padding: 2em 1em 1em;
		h2,
		h3,
		p {
			margin: 0 auto;
			max-width: 650px;
		}
		h2 {
			color: $mity-blue;
			font-size: 1.5em;
			font-weight: bold;
		}
		h3 {
			font-size: 1em;
			padding: 5px 0 20px;
		}
		p {
			font-size: 1em;
			padding-bottom: 25px;
		}
	}
	.about-mity-images {
		display: flex;
		flex-direction: column;
		.about-mity-left {
			img:nth-child(2) {
				display: none;
			}
		}
		.about-mity-right {
			img:nth-child(2) {
				display: none;
			}
		}
	}
}
@media (min-width: 600px) {
	.about-mity .about-mity-images {
		flex-direction: row;
		img {
			height: 100%;
		}
	}
}
@media (min-width: 1200px) {
	.about-mity {
		margin-bottom: 60px;
		.about-mity-content {
			h2 {
				font-size: 2.5em;
			}
			h3 {
				margin-top: -4px;
				padding-bottom: 28px;
			}
			p {
				padding: 0 0 30px;
			}
		}
		.about-mity-images {
			position: relative;
			.about-mity-left {
				display: flex;
				flex-direction: column;
				position: absolute;
				margin-top: -15px;
				max-width: 275px;
				top: -450px;
				img:nth-child(1) {
					width: 100%;
				}
				img:nth-child(2) {
					display: block;
					width: 90%;
				}
			}
			.about-mity-right {
				align-items: flex-end;
				display: flex;
				flex-direction: column;
				margin-top: -24px;
				position: absolute;
				max-width: 275px;
				top: -440px;
				right: 0;
				img:nth-child(1) {
					width: 100%;
				}
				img:nth-child(2) {
					display: block;
					width: 79%;
				}
			}
		}
	}
}
</style>
