<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
	components: { VueSlickCarousel },
	created() {
		const breaks = document.getElementsByClassName("partner-logo-break");
	},
	data() {
		return {
			settings: {
				arrows: false,
				dots: true,
				infinite: true,
				rows: 4,
				speed: 500,
				slidesToShow: 3,
				slidesToScroll: 3,
				touchThreshold: 5,
				responsive: [
					{
						breakpoint: 8000,
						settings: "unslick",
					},
					{
						breakpoint: 1100,
						settings: {
							slidesToScroll: 3,
							slidesToShow: 3,
						},
					},
					{
						breakpoint: 768,
						settings: {
							slidesToScroll: 3,
							slidesToShow: 3,
						},
					},
					{
						breakpoint: 480,
						settings: {
							slidesToScroll: 1,
							slidesToShow: 2,
						},
					},
				],
			},
		};
	},
};
</script>

<template lang="pug">
   section#partners.our-partners
        h2 OUR PARTNERS
        VueSlickCarousel.partner-logos(v-bind="settings")
            .logo.logo-7
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_Marriott_Logo.jpg" alt="marriott logo").partner-logo
            .logo.logo-7
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_Hilton_Logo.jpg" alt="hilton logo").partner-logo
            .logo.logo-7
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_Hyatt_Logo.jpg" alt="hyatt logo").partner-logo
            .logo.logo-7
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_BuyBoard_Logo.jpg" alt="buyboard logo").partner-logo
            .logo.logo-7
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_IAVM_Logo.jpg" alt="iavm logo").partner-logo
            .logo.logo-7
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_HardRockHotel_Logo.jpg" alt="hard rock logo").partner-logo
            .logo.logo-7
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_HarvardU_Logo.jpg" alt="harvard logo").partner-logo
            .logo.logo-8
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_DirectSupply_Logo.jpg" alt="direct supply logo").partner-logo
            .logo.logo-8
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_Aramark_Logo.jpg" alt="aramark logo").partner-logo
            .logo.logo-8
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_HomeDepot_Logo.jpg" alt="home depot logo").partner-logo
            .logo.logo-8
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_WaltDisneyCompany_Logo.jpg" alt="walt disney logo").partner-logo
            .logo.logo-8
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_LVRaiders_Logo.jpg" alt="raiders logo").partner-logo
            .logo.logo-8
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_EI_Logo.jpg" alt="ei logo").partner-logo
            .logo.logo-8
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_ACUI_Logo.jpg" alt="acui logo").partner-logo
            .logo.logo-8
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_COOP_Logo.jpg" alt="coop logo").partner-logo
            .logo.logo-7
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_GSA_Logo.jpg" alt="gsa logo").partner-logo
            .logo.logo-7
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_TXMAS_Logo.jpg" alt="txmas logo").partner-logo
            .logo.logo-7
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_ChoiceHotels_Logo.jpg" alt="choice hotels logo").partner-logo
            .logo.logo-7
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_CMAS_Logo.jpg" alt="cmas logo").partner-logo
            .logo.logo-7
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_Spectra_Logo.jpg" alt="spectra logo").partner-logo
            .logo.logo-7
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_ClubProcure_Logo.jpg" alt="club procure logo").partner-logo
            .logo.logo-7
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_CORD_Logo.jpg" alt="cord logo").partner-logo
            .logo.logo-7
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_BenjaminWest_Logo.jpg" alt="benjamin west logo").partner-logo
            .logo.logo-7
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_CaesarsEntertainment_Logo.jpg" alt="caesars entertainment logo").partner-logo
            .logo.logo-7
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_EdDon_Logo.jpg" alt="ed don logo").partner-logo
            .logo.logo-7
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_ASM_Logo.jpg" alt="asm logo").partner-logo
            .logo.logo-7
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_Motion_Logo.jpg" alt="motion logo").partner-logo
            .logo.logo-7
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_Advacare_Logo.jpg" alt="adva care logo").partner-logo
            .logo.logo-7
                img(src="../../images/our_partners/2020_MITYInc_Website_Pieces_PAPC_Logo.jpg" alt="papc logo").partner-logo
</template>

<style lang="scss">
@import "../../style/mityinc-browser.scss";
.our-partners {
	background-color: $light-gray;
	padding: 50px 0;
	h2 {
		font-size: 1.5em;
		margin-bottom: 50px;
	}
	.partner-logos {
		margin: 0 auto;
		max-width: 1200px;
		.logo {
			align-items: center;
			display: flex !important;
			min-height: 120px;
			text-align: center;

			&.logo-7 {
				width: 14.2%;
			}

			&.logo-8 {
				width: 12.5%;
			}
		}
		.partner-logo {
			max-height: 75px;
			max-width: 100px;
			margin: 20px 0;
		}
		.slick-list {
			.slick-track {
				.slick-slide {
					display: flex;
					flex-direction: column;
					align-items: center;
				}
			}
		}

		.slick-dots {
			bottom: -20px;
			li {
				button {
					background: $gray-button;
					border-radius: 100px;
					height: 15px;
					width: 15px;
					&:before {
						content: none;
					}
				}
				&.slick-active {
					button {
						background: $blue;
					}
				}
			}
		}
	}
}
@media (min-width: 1100px) {
	.our-partners {
		.partner-logos {
			align-items: center;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			.partner-logo {
				margin: 21px;
				max-height: 75px;
				max-width: 100px;
			}
		}
	}
}
</style>
