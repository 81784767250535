var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "leadership" }, [
      _c("h2", [_vm._v("MEET THE LEADERSHIP TEAM")]),
      _c("p", [
        _vm._v(
          "We are a team of innovators, thought leaders, and creative problem solvers obsessed with serving our business partners so they can be successful."
        )
      ]),
      _c("div", { staticClass: "leaders" }, [
        _c("div", { staticClass: "leader" }, [
          _c("img", {
            attrs: {
              src: require("../../images/leadership_team/2020_MITYInc_Website_Kevin_Image.jpg"),
              alt: "Kevin McCoy"
            }
          }),
          _c("h4", [_vm._v("Kevin McCoy")]),
          _c("p", [_vm._v("CEO")])
        ]),
        _c("div", { staticClass: "leader" }, [
          _c("img", {
            attrs: {
              src: require("../../images/leadership_team/2020_MITYInc_Website_Pieces_AlexiDobsky_Image.jpg"),
              alt: "Alexei Dobsky"
            }
          }),
          _c("h4", [_vm._v("Alexei Dobsky")]),
          _c("p", [_vm._v("Executive V.P. of Operations")])
        ]),
        _c("div", { staticClass: "leader" }, [
          _c("img", {
            attrs: {
              src: require("../../images/leadership_team/2023_MITYInc_Website_Pieces_Ben-Harris.jpg"),
              alt: "Ben Harris"
            }
          }),
          _c("h4", [_vm._v("Ben Harris")]),
          _c("p", [_vm._v("COO")])
        ]),
        _c("div", { staticClass: "break" }),
        _c("div", { staticClass: "leader" }, [
          _c("img", {
            attrs: {
              src: require("../../images/leadership_team/2020_MITYInc_Website_Pieces_SteveJ_Image.jpg"),
              alt: "Stephen Jorgensen"
            }
          }),
          _c("h4", [_vm._v("Stephen Jorgensen")]),
          _c("p", [_vm._v("V.P. of Marketing & Product")])
        ]),
        _c("div", { staticClass: "leader" }, [
          _c("img", {
            attrs: {
              src: require("../../images/leadership_team/2020_MITYInc_Website_Pieces_BrianK_Image.jpg"),
              alt: "Brian Keding"
            }
          }),
          _c("h4", [_vm._v("Brian Keding")]),
          _c("p", [_vm._v("CFO")])
        ]),
        _c("div", { staticClass: "leader" }, [
          _c("img", {
            attrs: {
              src: require("../../images/leadership_team/2020_MITYInc_Website_Pieces_MelissaLoader_Image.jpg"),
              alt: "Melissa Loader"
            }
          }),
          _c("h4", [_vm._v("Melissa Loader")]),
          _c("p", [
            _vm._v("Executive Director of Product Marketing & Development")
          ])
        ]),
        _c("div", { staticClass: "break" }),
        _c("div", { staticClass: "leader" }, [
          _c("img", {
            attrs: {
              src: require("../../images/leadership_team/2023_MITYInc_Website_Pieces_Jen-Richardson.jpg"),
              alt: "Jen Richardson"
            }
          }),
          _c("h4", [_vm._v("Jen Richardson")]),
          _c("p", [_vm._v("Executive Director of Human Resources")])
        ]),
        _c("div", { staticClass: "leader" }, [
          _c("img", {
            attrs: {
              src: require("../../images/leadership_team/2020_MITYInc_Website_Pieces_JeffScott_Image.jpg"),
              alt: "Jeff Scott"
            }
          }),
          _c("h4", [_vm._v("Jeff Scott")]),
          _c("p", [_vm._v("V.P. of Sales")])
        ]),
        _c("div", { staticClass: "leader" }, [
          _c("img", {
            attrs: {
              src: require("../../images/leadership_team/2023_MITYInc_Website_Pieces_Mike-Schultz.jpg"),
              alt: "Mike Schultz"
            }
          }),
          _c("h4", [_vm._v("Mike Schultz")]),
          _c("p", [_vm._v("V.P. of Supply Chain")])
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-630dac96", { render: render, staticRenderFns: staticRenderFns })
  }
}