<script>
import Header from "../components/Header.vue";
import HeroVideo from "../components/home/HeroVideo.vue";
import BrandColors from "../components/home/BrandColors.vue";
import Brands from "../components/home/Brands.vue";
import BrandsIntro from "../components/home/BrandsIntro.vue";
import ProductStats from "../components/home/ProductStats.vue";
import ProductPictures from "../components/home/ProductPictures.vue";
import OurPartners from "../components/home/OurPartners.vue";
import Testimonials from "../components/home/Testimonials.vue";
import newsLetterSignup from "../components/home/NewsLetterSignup.vue";
import About from "../components/home/About.vue";
import WhatWeDo from "../components/home/WhatWeDo.vue";
import VissionMissionValues from "../components/home/VissionMissionValues.vue";
import Leadership from "../components/home/Leadership.vue";
import MityBrandLogos from "../components/home/MityBrandLogos.vue";
import WorkWithUs from "../components/home/WorkWithUs.vue";
import Footer from "../components/Footer.vue";
export default {
	name: "Home",
	metaInfo() {
		return {
			title: "Modern Commercial Furniture Manufacturer | Make It MITY",
			meta: [
				{ name: "description", content: "Shop modern commercial furniture with MITY global manufacturing. Built with durable materials on high-quality service for over 30 years. MITY Makes It Easy!" },
				{ name: "robots", content: this.robots },
			],
		};
	},
	data() {
		return {
			isIE11: !!window.MSInputMethodContext && !!document.documentMode,
			URL: window.location.origin,
			robots: "index, follow",
		};
	},
	components: {
		Header,
		HeroVideo,
		BrandColors,
		Brands,
		BrandsIntro,
		ProductStats,
		ProductPictures,
		OurPartners,
		Testimonials,
		newsLetterSignup,
		About,
		WhatWeDo,
		VissionMissionValues,
		Leadership,
		MityBrandLogos,
		WorkWithUs,
		Footer,
	},
	mounted: function() {
		if (this.URL === "http://localhost:8080" || this.URL === "https://staging.mityinc.com") {
			this.robots = "noindex, nofollow";
		}
	},
};
</script>

<template lang="pug">
	.container
		<Header />
		<HeroVideo />
		main
			<BrandColors class="top-brand-color" />
			<Brands />
			<BrandsIntro />
			<ProductStats v-if="!isIE11" />
			<ProductPictures />
			<OurPartners />
			<Testimonials />
			<newsLetterSignup v-if="!isIE11" />
			<About />
			<WhatWeDo />
			<VissionMissionValues />
			<Leadership />
			<MityBrandLogos />
			<WorkWithUs />
			<BrandColors class="bottom-brand-color" />
			<Footer />
</template>

<style lang="scss">
@import "../style/mityinc-browser.scss";
body {
	.break {
		flex-basis: 100%;
		height: 0;
	}
	.brand-color-break {
		width: 100%;
	}
}
@media (min-width: 1440px) {
	body {
		.top-brand-color {
			margin: 0 auto;
			max-width: 1440px;
		}
	}
}
</style>
