var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-damage" },
    [
      _c("Header"),
      _c(
        "main",
        [
          _c("Logos"),
          _c("h2", [_vm._v("Reporting Freight Damage")]),
          _c("h3", [
            _vm._v(
              "Did you find freight damage after inspecting items in your order?"
            )
          ]),
          _vm._m(0),
          _c("p", [
            _vm._v(
              "To submit your damage report, please provide the following information:"
            )
          ]),
          _vm._m(1)
        ],
        1
      ),
      _c("BrandColors"),
      _c("Footer")
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "warning" }, [
      _c("p", [_c("strong", [_vm._v(" Please Note:")])]),
      _c("ul", [
        _c("li", [
          _vm._v("Freight damages must be reported "),
          _c("strong", [_vm._v("within 48 hours of shipment receipt.")])
        ]),
        _c("li", [
          _vm._v("Failure to file the claim within 48 hours "),
          _c("strong", [_vm._v("could void the warranty on your products.")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form" }, [
      _c("script", { attrs: { type: "application/javascript" } }, [
        _vm._v(
          'hbspt.forms.create({ region: "na1", portalId: "6724089", formId:"c65317f8-bb44-464f-8606-8cee3755f556" });\n'
        )
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-990162ae", { render: render, staticRenderFns: staticRenderFns })
  }
}