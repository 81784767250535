<template lang="pug">
	.page-notfound
		Header
		main
			p Not Found
		BrandColors
		Footer
</template>

<script>
import Header from "../components/Header.vue";
import BrandColors from "../components/home/BrandColors.vue";
import Footer from "../components/Footer.vue";

export default {
	name: "NotFound",
	metaInfo: {
		title: "404 - Page Not Found | MITY Inc.",
		meta: [
			{ name: "description", content: "Page cannot be found" },
			{ name: "robots", content: "noindex, nofollow" },
		],
	},
	data() {
		return {};
	},
	components: { Header, BrandColors, Footer },
};
</script>

<style lang="scss">
@import "../style/mityinc-browser.scss";

main {
	max-width: 1230px;
	margin: 125px auto 0;
	min-height: 350px;
}
</style>
