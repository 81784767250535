var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "brands-intro" }, [
      _c("h2", [_vm._v("MITY Incorporated Product Brands")]),
      _c("p", [
        _vm._v(
          "Backed by decades of experience and design, companies and organizations from around the world trust MITY product brands to help them host successful meetings and events, educate students, connect the faithful, and care for patients and residents."
        )
      ]),
      _c("p", [
        _vm._v(
          "Our customers trust MITY brands to provide durable products designed to fit your venue so you can focus on serving your customers."
        )
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0b80aec0", { render: render, staticRenderFns: staticRenderFns })
  }
}