<script>
import VisitIcon from "../svg/VisitIcon.vue";

export default {
	components: { VisitIcon },
};
</script>

<template lang="pug">
	section#careers.work-with-us
		h2 WORK WITH US
		p MityLite is not your typical commercial furniture manufacturer. We are a team of innovators, thought leaders, and creative problem solvers obsesed with serving our business partners so they can be successful.
		p We employ hundreds of American and Canadian workers with a variety of skill sets and interests. If you're ready to join a dynamic team where your contribution will matter, then we're excited to work with you.
		.work-with-us-images
			img(src="../../images/work_with_us/2020_MITYInc_Website_Pieces_CornHole_Image.jpg" alt="employees playing a game")
			img(src="../../images/work_with_us/2020_MITYInc_Website_Pieces_PancakeBreakfast_Image.jpg" alt="employees gathered outside eating pancakes for breakfast")
			div
				h3 What we bring to the table.
				ul
					li Medical, dental, & vision insurance options including an HSA matching program
					li Retirement program with a 401k matching program
					li Employee life insurance
					li Free Employee Assistance Program for mental & emotional wellness
					li Team building activities & parties including pancake breakfasts & corn-hole competitions
				a(href="https://www.paycomonline.net/v4/ats/web.php/jobs?clientkey=9CAFE0B9E9A63840A7A5BBF7C294E6B6" target="_blank")
					VisitIcon
					p View Job Openings

			img(src="../../images/work_with_us/2020_MITYInc_Website_Pieces_UpholsteryTeam_Image.jpg" alt="upholstry team posing behind two chairs")
			img(src="../../images/work_with_us/2020_MITYInc_Website_Pieces_Massage_Image.jpg" alt="employees receiving professional massages")
			img(src="../../images/work_with_us/2020_MITYInc_Website_Pieces_SalesGala_Image.jpg" alt="two men in suits shaking hands")
			img(src="../../images/work_with_us/2020_MITYInc_Website_Pieces_Sales_Image.jpg" alt="Sales manager helping sales employee at a computer")
			img(src="../../images/work_with_us/2020_MITYInc_Website_Pieces_Manufacturing_Image.jpg" alt="women and man standing in front of papers talking")

</template>

<style lang="scss">
@import "../../style/mityinc-browser.scss";
.work-with-us {
	background-color: $light-gray-orange;
	padding-top: 2em;
	h2 {
		color: $mity-blue;
		font-size: 1.5em;
		padding-bottom: 1em;
	}
	> p {
		margin: 0 auto;
		max-width: 750px;
		padding: 0 1em 1em;
	}
	.work-with-us-images {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin: auto;
		max-width: 1200px;
		h3 {
			color: $white;
		}
		img {
			object-fit: cover;
			width: 100%;
		}
		div {
			background-color: $blue;
			color: $white;
			padding: 1em;
			text-align: left;
			width: 100%;
			h3 {
				font-size: 1.2em;
				padding-bottom: 1em;
			}
			ul {
				list-style: disc;
				li {
					margin-left: 1em;
					margin-bottom: 1em;
				}
			}
			a {
				align-items: center;
				border: 1px solid $white;
				display: flex;
				margin: 0 auto;
				padding: 5px 10px;
				width: 225px;
				&:hover {
					background-color: $visit-hover-background;
					border: 1px solid $visit-hover-background;
				}
				&:active {
					background-color: $visit-active-background;
					border: 1px solid $visit-active-background;
				}
				&:hover,
				&:active {
					svg {
						fill: $font-color;
					}
					p {
						color: $font-color;
					}
				}
				img {
					margin: 10px;
					width: auto;
				}
				p {
					color: $white;
					margin: 0;
				}
			}
		}
		img:nth-child(2),
		img:nth-child(4),
		img:nth-child(5),
		img:nth-child(7) {
			display: none;
		}
	}
}

@media (min-width: 768px) {
	.work-with-us {
		h2 {
			font-size: 1.6em;
			padding: 1em 0;
		}
		.work-with-us-images {
			padding-top: 24px;
			& > div,
			img {
				margin: 10px;
				max-width: 575px;
			}
		}
	}
}
@media (min-width: 1200px) {
	.work-with-us .work-with-us-images {
		img:nth-child(2),
		img:nth-child(4),
		img:nth-child(5),
		img:nth-child(7) {
			display: block;
		}
	}
}
</style>
