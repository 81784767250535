<script>
import HubSpotForm from "./HubSpotForm.vue";

export default {
	components: { HubSpotForm },
	data() {
		return {
			HubspotFormId: "410ebfd0-7b45-453a-a1fc-169c8550c144",
		};
	},
};
</script>

<template lang="pug">
    section.newsLetterSignup
        h2 Want to Stay Up to Date? Sign Up for Our Emails
        p Receive product updates and promotional news from MITY Inc.
        HubSpotForm(:HubspotFormId="HubspotFormId")
</template>

<style lang="scss">
@import "../../style/mityinc-browser.scss";
.newsLetterSignup {
	background-color: $light-gray-orange;
	margin: 0 auto 8em;
	max-width: 1440px;
	padding: 3em 1em;
	h2,
	p {
		color: $mity-blue;
	}
	h2 {
		font-size: 1.4em;
		margin-bottom: 0.5em;
	}
	p {
		text-align: center;
		margin-bottom: 2em;
	}
}
</style>
