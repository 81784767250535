<script>
export default {};
</script>

<template lang="pug">
    section.brands-intro
        h2 MITY Incorporated Product Brands
        p Backed by decades of experience and design, companies and organizations from around the world trust MITY product brands to help them host successful meetings and events, educate students, connect the faithful, and care for patients and residents.
        p Our customers trust MITY brands to provide durable products designed to fit your venue so you can focus on serving your customers.

</template>

<style lang="scss">
@import "../../style/mityinc-browser.scss";
.brands-intro {
	background: rgb(233, 238, 244);
	background: linear-gradient(90deg, rgba(233, 238, 244, 1) 0%, rgba(240, 243, 236, 1) 100%);
	padding: 2em 1em 1em;
	h2 {
		color: $mity-blue;
		font-size: 1.4em;
		margin-bottom: 25px;
	}
	p {
		line-height: 2em;
		margin: 0 auto;
		max-width: 750px;
		padding-bottom: 25px;
	}
}
@media (min-width: 700px) {
	.brands-intro {
		text-align: left;
	}
}
</style>
