var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("svg", [
    _c("path", {
      staticClass: "cls-1",
      attrs: {
        d:
          "M15.15,3.48H2a1,1,0,0,0-1,1V26.1a1,1,0,0,0,1,1H23.65a1,1,0,0,0,1-1V13a1,1,0,1,0-2,0V25.11H3V5.46H15.15a1,1,0,1,0,0-2Z"
      }
    }),
    _c("path", {
      staticClass: "cls-1",
      attrs: {
        d:
          "M26.25.87H19.41a1,1,0,0,0,0,2h4.44L11.73,15a1,1,0,0,0,0,1.4,1,1,0,0,0,1.4,0L25.26,4.27V8.59a1,1,0,1,0,2,0V1.87a1,1,0,0,0-.07-.38A1,1,0,0,0,26.63,1a1,1,0,0,0-.38-.08Z"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-24a3740e", { render: render, staticRenderFns: staticRenderFns })
  }
}