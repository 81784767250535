var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 223.68 76.31"
      }
    },
    [
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M216.94,21.65c.36,0,.58-.18.58-.46v0c0-.29-.22-.46-.58-.46h-.73v.94Zm-1.29-1.44H217a1.24,1.24,0,0,1,.9.31.85.85,0,0,1,.23.62v0a.87.87,0,0,1-.68.88l.79,1.11h-.66l-.72-1h-.61v1h-.56Zm3.65,1.55v0a2.52,2.52,0,1,0-5,0v0a2.48,2.48,0,0,0,2.51,2.51,2.51,2.51,0,0,0,2.52-2.53m-5.32,0v0a2.81,2.81,0,0,1,5.61,0v0a2.81,2.81,0,0,1-5.61,0"
        }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M.5.5H7.72V25.26h.16a13.3,13.3,0,0,1,5.17-5.08,14.83,14.83,0,0,1,7.37-2.05c5.33,0,13.86,3.27,13.86,17V58.7H27.06V35.91c0-6.39-2.37-11.8-9.18-11.8a10.36,10.36,0,0,0-9.67,7.21,8.77,8.77,0,0,0-.49,3.45V58.7H.5Z"
        }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M48.37,39c0,8.69,5,15.24,12.05,15.24,6.89,0,12.06-6.47,12.06-15.41,0-6.72-3.37-15.24-11.89-15.24S48.37,31.41,48.37,39m31.48-.41c0,14.67-10.16,21.07-19.75,21.07-10.74,0-19-7.88-19-20.42,0-13.28,8.69-21.06,19.67-21.06,11.4,0,19.1,8.28,19.1,20.41"
        }
      }),
      _c("rect", {
        staticClass: "cls-1",
        attrs: { x: "86.34", y: "0.5", width: "7.21", height: "58.2" }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M104.29,51.33a18.93,18.93,0,0,0,9.51,2.87c5.24,0,7.7-2.63,7.7-5.9,0-3.45-2-5.33-7.38-7.3-7.13-2.54-10.49-6.48-10.49-11.23,0-6.39,5.17-11.64,13.69-11.64a19.85,19.85,0,0,1,9.76,2.46l-1.81,5.24a15.46,15.46,0,0,0-8.11-2.29c-4.27,0-6.64,2.46-6.64,5.41,0,3.28,2.37,4.75,7.54,6.72,6.88,2.62,10.41,6.07,10.41,12,0,7-5.41,11.88-14.84,11.88a22.65,22.65,0,0,1-11.15-2.7Z"
        }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M157.72,38.46c-7.87-.17-16.81,1.23-16.81,8.93,0,4.67,3.11,6.89,6.8,6.89a9.86,9.86,0,0,0,9.59-6.64,7.45,7.45,0,0,0,.42-2.3Zm1.06,20.24-.58-5H158a14.78,14.78,0,0,1-12.13,5.89c-8,0-12.13-5.65-12.13-11.39,0-9.59,8.52-14.83,23.85-14.76v-.81c0-3.28-.9-9.18-9-9.18A19.83,19.83,0,0,0,138.2,26.4l-1.63-4.75a24.6,24.6,0,0,1,13-3.52c12.13,0,15.08,8.27,15.08,16.23V49.19a55.94,55.94,0,0,0,.66,9.51Z"
        }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M202.63,34.68a10.53,10.53,0,0,0-10.49-10.9c-6.88,0-11.8,5.82-11.8,15,0,7.79,3.93,14.27,11.72,14.27a10.55,10.55,0,0,0,10-7.38,12.52,12.52,0,0,0,.57-3.85ZM210.09,19c-.16,2.87-.33,6.07-.33,10.9V53c0,9.09-1.8,14.67-5.65,18.11-3.85,3.61-9.43,4.76-14.43,4.76-4.75,0-10-1.15-13.2-3.28l1.81-5.5a22.26,22.26,0,0,0,11.64,3.12c7.38,0,12.79-3.85,12.79-13.85V51.9h-.17c-2.21,3.69-6.47,6.64-12.62,6.64-9.84,0-16.89-8.36-16.89-19.34,0-13.45,8.77-21.07,17.87-21.07,6.89,0,10.66,3.6,12.38,6.88h.17l.32-6Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-fe120a0c", { render: render, staticRenderFns: staticRenderFns })
  }
}