var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "what-we-do" }, [
      _c("h2", [_vm._v("WHAT WE DO")]),
      _c("div", { staticClass: "what-we-do-items" }, [
        _c("div", { staticClass: "what-we-do-item manufacturing" }, [
          _c("img", {
            attrs: {
              src: require("../../images/what_we_do/2020_MITYInc_Website_Pieces_Manufacturing_Icon.png"),
              alt: "hand holding wrench icon"
            }
          }),
          _c("div", { staticClass: "what-we-do-item-content" }, [
            _c("h3", [_vm._v("Manufacturing")]),
            _c("p", [
              _vm._v(
                "We manage high-quality product brands that manufacture products that provide the best customer value."
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "what-we-do-item design" }, [
          _c("img", {
            attrs: {
              src: require("../../images/what_we_do/2020_MITYInc_Website_Pieces_Design_Icon.png"),
              alt: "light bulb combined with gear icon"
            }
          }),
          _c("div", { staticClass: "what-we-do-item-content" }, [
            _c("h3", [_vm._v("Design & Customization")]),
            _c("p", [
              _vm._v(
                "Our in-house teams work with designers to find and specify the perfect products to complement any decor."
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "what-we-do-item sourcing" }, [
          _c("img", {
            attrs: {
              src: require("../../images/what_we_do/2020_MITYInc_Website_Pieces_Sourcing_Icon.png"),
              alt: "magnify glass over globe icon"
            }
          }),
          _c("div", { staticClass: "what-we-do-item-content" }, [
            _c("h3", [_vm._v("Sourcing")]),
            _c("p", [
              _vm._v(
                "Our brands keep costs low by working hard to find the most cost-effective sourcing partners for our customers."
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "what-we-do-item value" }, [
          _c("img", {
            attrs: {
              src: require("../../images/what_we_do/2020_MITYInc_Website_Pieces_Value_Icon.png"),
              alt: "coin going into piggy bank icon"
            }
          }),
          _c("div", { staticClass: "what-we-do-item-content" }, [
            _c("h3", [_vm._v("Value")]),
            _c("p", [
              _vm._v(
                "We stand behind the products built by our brands by offering industry-leading warranties."
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "what-we-do-item service" }, [
          _c("img", {
            attrs: {
              src: require("../../images/what_we_do/2020_MITYInc_Website_Pieces_Service_Icon.png"),
              alt: "headset with a shape of person inside bubble icon"
            }
          }),
          _c("div", { staticClass: "what-we-do-item-content" }, [
            _c("h3", [_vm._v("Service")]),
            _c("p", [
              _vm._v(
                "Our customer service and sales teams are located in the United States and Canada to provide the best domestic service."
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "what-we-do-item inovative" }, [
          _c("img", {
            attrs: {
              src: require("../../images/what_we_do/2020_MITYInc_Website_Pieces_Innovation_Icon.png"),
              alt: "two hands shaking icon"
            }
          }),
          _c("div", { staticClass: "what-we-do-item-content" }, [
            _c("h3", [_vm._v("Innovative Solutions")]),
            _c("p", [
              _vm._v(
                "Our brands work with customers to design new products that fit individual needs and changing industries."
              )
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1ced0c66", { render: render, staticRenderFns: staticRenderFns })
  }
}