var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "header" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "a",
        {
          attrs: { href: "/#home" },
          on: {
            click: function($event) {
              _vm.isActive = false
            }
          }
        },
        [_c("MityIncLogo")],
        1
      ),
      _c("h1", { staticClass: "mity-name-text" }, [_vm._v("MITY Inc.")]),
      _c(
        "button",
        {
          staticClass: "hamburger-icon",
          attrs: { id: "navbar-toggle" },
          on: {
            click: function($event) {
              _vm.isActive = !_vm.isActive
            }
          }
        },
        [
          _c("div", { staticClass: "bar1", class: { active: _vm.isActive } }),
          _c("div", { staticClass: "bar2", class: { active: _vm.isActive } }),
          _c("div", { staticClass: "bar3", class: { active: _vm.isActive } })
        ]
      ),
      _c(
        "nav",
        { class: { active: _vm.isActive }, attrs: { id: "main-nav" } },
        [
          _c(
            "a",
            {
              attrs: { href: "/#brands" },
              on: {
                click: function($event) {
                  _vm.isActive = false
                }
              }
            },
            [_vm._v("Our Brands")]
          ),
          _c(
            "a",
            {
              attrs: { href: "/#partners" },
              on: {
                click: function($event) {
                  _vm.isActive = false
                }
              }
            },
            [_vm._v("Partners")]
          ),
          _c(
            "a",
            {
              attrs: { href: "/#about" },
              on: {
                click: function($event) {
                  _vm.isActive = false
                }
              }
            },
            [_vm._v("About Us")]
          ),
          _c(
            "a",
            {
              attrs: { href: "/#careers" },
              on: {
                click: function($event) {
                  _vm.isActive = false
                }
              }
            },
            [_vm._v("Careers")]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-61dd7a3d", { render: render, staticRenderFns: staticRenderFns })
  }
}