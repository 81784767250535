<script>
import MityLiteLogo from "../svg/MityLiteLogo.vue";
import HolsagLogo from "../svg/HolsagLogo.vue";
import BertoliniLogo from "../svg/BertoliniLogo.vue";
import BrodaLogo from "../svg/BrodaLogo.vue";
import VisitIcon from "../svg/VisitIcon.vue";

export default {
	components: { MityLiteLogo, HolsagLogo, BertoliniLogo, BrodaLogo, VisitIcon },
};
</script>

<template lang="pug">
	section#brands.brands
		a(href="https://www.mitylite.com/" target="_blank").brand.mitylite
			MityLiteLogo.brand-title
			p.brand-text Manufactures durable, affordably priced, easy-to-use, & reliable furniture for facilities where people gather
			.visit-box
				VisitIcon
				p Visit MityLite
		a(href="https://holsag.com/" target="_blank").brand.holsag
			HolsagLogo.brand-title
			p.brand-text Leading manufacturer of commercial hardwood and faux wood seating with a focus on exceptional quality and customer service
			.visit-box
				VisitIcon
				p Visit Holsag
		a(href="https://bertolinidirect.com/" target="_blank").brand.bertolini
			BertoliniLogo.brand-title
			p.brand-text One of the most trusted names in worship seating with more than 50 years of experience
			.visit-box
				VisitIcon
				p Visit Bertolini
		a(href="https://brodaseating.com/" target="_blank").brand.broda
			BrodaLogo.brand-title
			p.brand-text Experts in long-term care seating where comfort is essential
			.visit-box
				VisitIcon
				p Visit Broda
</template>

<style lang="scss">
@import "../../style/mityinc-browser.scss";

.brands {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	max-width: 1230px;
	margin: auto;
	padding: 16px 0 0;
	text-align: center;
	.brand {
		align-items: center;
		background-repeat: no-repeat;
		display: flex;
		box-shadow: $card-drop-shadow;
		flex-direction: column;
		height: 300px;
		justify-content: space-evenly;
		padding: 0 15px;
		width: 100%;
		&:hover .visit-box {
			background-color: $visit-hover-background;
			border: 1px solid $visit-hover-background;
		}
		&:active .visit-box {
			background-color: $visit-active-background;
			border: 1px solid $visit-active-background;
		}
		&:active,
		&:hover {
			.visit-box {
				svg {
					fill: $black;
				}
				p {
					color: $black;
				}
			}
		}
		.brand-title {
			fill: $white;
			margin: 20px 0;
			width: 15em;
		}
		.brand-text {
			color: $white;
			font-size: 0.8em;
			width: 100%;
		}
		.visit-box {
			align-items: center;
			border: 1px solid $white;
			color: $white;
			display: flex;
			justify-content: space-around;
			padding: 5px 8px;
			width: 180px;
			margin-bottom: 20px;
			img {
				height: 27px;
			}
			svg {
				fill: $white;
				height: 2em;
				width: 2em;
			}
			p {
				color: $white;
			}
		}
	}
	.mitylite {
		background-image: url("../../images/product_brands/2020_MITYInc_Website_Pieces_MityLite_Color_Board.jpg");
	}
	.holsag {
		background-image: url("../../images/product_brands/2020_MITYInc_Website_Pieces_Holsag_Color_Board.jpg");
	}
	.bertolini {
		background-image: url("../../images/product_brands/2020_MITYInc_Website_Pieces_Bertolini__Color_Board.jpg");
	}
	.broda {
		background-image: url("../../images/product_brands/2020_MITYInc_Website_Pieces_Broda_Color_Board.jpg");
	}
	.break {
		flex-basis: 0;
	}
}
@media (min-width: 470px) {
	.brands {
		.brand {
			margin: 10px 0;
			width: 45%;
			.brand-title {
				flex: 1;
				object-fit: none;
				width: 10em;
			}
			.brand-text {
				flex: 4;
			}
			.visit-box {
				flex: 1;
			}
		}
	}
}
@media (min-width: 850px) {
	.brands {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 52px 0 57px;
		max-width: 1230px;
		.brand {
			width: 24%;
		}
	}
}
</style>
