var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "brand-color-break" }, [
      _c("div", { staticClass: "bright-orange" }),
      _c("div", { staticClass: "moderate-orange" }),
      _c("div", { staticClass: "dark-grayish-lime-green" }),
      _c("div", { staticClass: "dark-moderage-green" }),
      _c("div", { staticClass: "very-soft-orange" }),
      _c("div", { staticClass: "bright-orange2" }),
      _c("div", { staticClass: "dark-blue" }),
      _c("div", { staticClass: "very-dark-blue" }),
      _c("div", { staticClass: "desaturated-dark-red" }),
      _c("div", { staticClass: "dark-red" })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-67dacf17", { render: render, staticRenderFns: staticRenderFns })
  }
}