var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer-section" }, [
    _c("div", { staticClass: "footer-mity-brand-logos" }, [
      _c(
        "a",
        { staticClass: "logo-image", attrs: { href: "/" } },
        [_c("MityIncLogo")],
        1
      ),
      _c(
        "a",
        {
          staticClass: "brand-image",
          attrs: { href: "https://www.mitylite.com/", target: "_blank" }
        },
        [_c("MityLiteLogo")],
        1
      ),
      _c(
        "a",
        {
          staticClass: "brand-image",
          attrs: { href: "https://holsag.com/", target: "_blank" }
        },
        [_c("HolsagLogo")],
        1
      ),
      _c(
        "a",
        {
          staticClass: "brand-image",
          attrs: { href: "https://bertolinidirect.com/", target: "_blank" }
        },
        [_c("BertoliniLogo")],
        1
      ),
      _c(
        "a",
        {
          staticClass: "brand-image",
          attrs: { href: "https://brodaseating.com/", target: "_blank" }
        },
        [_c("BrodaLogo")],
        1
      )
    ]),
    _vm._m(0),
    _c("div", { staticClass: "social-email" }, [
      _c("div", { staticClass: "social-links" }, [
        _c(
          "a",
          {
            attrs: {
              href: "https://www.facebook.com/mityinc/",
              target: "_blank"
            }
          },
          [_c("FacebookIcon")],
          1
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://www.linkedin.com/company/mity-inc",
              target: "_blank"
            }
          },
          [_c("LinkedInIcon")],
          1
        )
      ]),
      !_vm.isIE11
        ? _c(
            "div",
            { staticClass: "email" },
            [
              _c("p", [_vm._v("Sign up for our emails")]),
              _c("HubSpotForm", { attrs: { HubspotFormId: _vm.HubspotFormId } })
            ],
            1
          )
        : _vm._e()
    ]),
    _c("p", { staticClass: "copyright" }, [
      _vm._v(
        "Copyright © " + _vm._s(_vm.currentYear) + " MITY. All rights reserved"
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "contact-info" }, [
      _c("a", { attrs: { href: "tel:1-800-909-8034" } }, [
        _vm._v("1-800-909-8034")
      ]),
      _c("span", { staticClass: "contact-info-separator" }, [_vm._v("|")]),
      _c("a", { attrs: { href: "mailto:info@mityinc.com" } }, [
        _vm._v("info@mityinc.com")
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-40ab164b", { render: render, staticRenderFns: staticRenderFns })
  }
}