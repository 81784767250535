var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "brands", attrs: { id: "brands" } }, [
    _c(
      "a",
      {
        staticClass: "brand mitylite",
        attrs: { href: "https://www.mitylite.com/", target: "_blank" }
      },
      [
        _c("MityLiteLogo", { staticClass: "brand-title" }),
        _c("p", { staticClass: "brand-text" }, [
          _vm._v(
            "Manufactures durable, affordably priced, easy-to-use, & reliable furniture for facilities where people gather"
          )
        ]),
        _c(
          "div",
          { staticClass: "visit-box" },
          [_c("VisitIcon"), _c("p", [_vm._v("Visit MityLite")])],
          1
        )
      ],
      1
    ),
    _c(
      "a",
      {
        staticClass: "brand holsag",
        attrs: { href: "https://holsag.com/", target: "_blank" }
      },
      [
        _c("HolsagLogo", { staticClass: "brand-title" }),
        _c("p", { staticClass: "brand-text" }, [
          _vm._v(
            "Leading manufacturer of commercial hardwood and faux wood seating with a focus on exceptional quality and customer service"
          )
        ]),
        _c(
          "div",
          { staticClass: "visit-box" },
          [_c("VisitIcon"), _c("p", [_vm._v("Visit Holsag")])],
          1
        )
      ],
      1
    ),
    _c(
      "a",
      {
        staticClass: "brand bertolini",
        attrs: { href: "https://bertolinidirect.com/", target: "_blank" }
      },
      [
        _c("BertoliniLogo", { staticClass: "brand-title" }),
        _c("p", { staticClass: "brand-text" }, [
          _vm._v(
            "One of the most trusted names in worship seating with more than 50 years of experience"
          )
        ]),
        _c(
          "div",
          { staticClass: "visit-box" },
          [_c("VisitIcon"), _c("p", [_vm._v("Visit Bertolini")])],
          1
        )
      ],
      1
    ),
    _c(
      "a",
      {
        staticClass: "brand broda",
        attrs: { href: "https://brodaseating.com/", target: "_blank" }
      },
      [
        _c("BrodaLogo", { staticClass: "brand-title" }),
        _c("p", { staticClass: "brand-text" }, [
          _vm._v("Experts in long-term care seating where comfort is essential")
        ]),
        _c(
          "div",
          { staticClass: "visit-box" },
          [_c("VisitIcon"), _c("p", [_vm._v("Visit Broda")])],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-54a02d3c", { render: render, staticRenderFns: staticRenderFns })
  }
}