<script>
export default {};
</script>

<template lang="pug">
	section.leadership
		h2 MEET THE LEADERSHIP TEAM
		p We are a team of innovators, thought leaders, and creative problem solvers obsessed with serving our business partners so they can be successful.
		.leaders
			.leader
				img(src="../../images/leadership_team/2020_MITYInc_Website_Kevin_Image.jpg" alt="Kevin McCoy")
				h4 Kevin McCoy
				p CEO
			.leader
				img(src="../../images/leadership_team/2020_MITYInc_Website_Pieces_AlexiDobsky_Image.jpg" alt="Alexei Dobsky")
				h4 Alexei Dobsky
				p Executive V.P. of Operations
			.leader
				img(src="../../images/leadership_team/2023_MITYInc_Website_Pieces_Ben-Harris.jpg" alt="Ben Harris")
				h4 Ben Harris
				p COO
			.break
			.leader
				img(src="../../images/leadership_team/2020_MITYInc_Website_Pieces_SteveJ_Image.jpg" alt="Stephen Jorgensen")
				h4 Stephen Jorgensen
				p V.P. of Marketing &amp; Product
			.leader
				img(src="../../images/leadership_team/2020_MITYInc_Website_Pieces_BrianK_Image.jpg" alt="Brian Keding")
				h4 Brian Keding
				p CFO
			.leader
				img(src="../../images/leadership_team/2020_MITYInc_Website_Pieces_MelissaLoader_Image.jpg" alt="Melissa Loader")
				h4 Melissa Loader
				p Executive Director of Product Marketing &amp; Development
			.break
			.leader
				img(src="../../images/leadership_team/2023_MITYInc_Website_Pieces_Jen-Richardson.jpg" alt="Jen Richardson")
				h4 Jen Richardson
				p Executive Director of Human Resources
			.leader
				img(src="../../images/leadership_team/2020_MITYInc_Website_Pieces_JeffScott_Image.jpg" alt="Jeff Scott")
				h4 Jeff Scott
				p V.P. of Sales
			.leader
				img(src="../../images/leadership_team/2023_MITYInc_Website_Pieces_Mike-Schultz.jpg" alt="Mike Schultz")
				h4 Mike Schultz
				p V.P. of Supply Chain
</template>

<style lang="scss">
@import '../../style/mityinc-browser.scss';
.leadership {
	background-color: $light-gray-blue;
	margin: auto;
	padding: 50px 1em;
	max-width: 1400px;
	text-align: center;
	h2 {
		color: $mity-blue;
		font-size: 1.5em;
		margin-bottom: 1em;
	}
	& > p {
		font-size: 1em;
		margin: auto;
		max-width: 750px;
		padding-bottom: 1em;
	}
	.leaders {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin: auto;
		max-width: 1200px;
		.ceo-break {
			flex-basis: 100%;
		}
		.break {
			flex-basis: 0;
		}
		.leader {
			margin: 1.8em;
			width: 180px;
			img {
				border-radius: 50%;
				width: 150px;
			}
			h4 {
				font-size: 1em;
				font-weight: bold;
				padding-top: 9px;
			}
			p {
				font-size: 0.76em;
				padding-top: 5px;
			}
		}
	}
}
@media (min-width: 768px) {
	.leadership {
		padding: 5em 1em 0;
	}
}
@media (min-width: 850px) {
	.leadership {
		background-color: $white;
		padding: 18em 1em 0;
	}
}
@media (min-width: 1220px) {
	.leadership {
		.leaders {
			.ceo-break {
				flex-basis: 0;
			}
			.break {
				flex-basis: 100%;
			}
		}
	}
}
</style>
