<script>
export default {};
</script>

<template lang="pug">
    section.vision-mission-values
        .vision-mission-container
            div.vision-mission-relative-container
                div.vision-mission-inner-container
                    .vision
                        img(src="../../images/company_values/2024_vision_icon.png" alt="human eye icon")
                        h3 Vision
                        p To furnish spaces for memorable gatherings, meaningful learning, and face-to-face connections.
                    .mission
                        img(src="../../images/company_values/2024_mission_icon.png" alt="arrow hitting bulls eye icon")
                        h3 Mission
                        p We take pride in manufacturing high-value, easy-to-use products that our customers trust while improving the lives of our employees and exceeding the expectations of our stakeholders.
                h2 COMPANY VALUES
        .values-container
            .value
                img(src="../../images/company_values/2020_MITYInc_Website_Pieces_MakeItSafe_Graphic.png" alt="stars over shield icon")
                h3 Make It Safe
                p We strive to be a safe place for employees to work, a safe choice for customers, and a safe investment for our stakeholders.
            .value
                img(src="../../images/company_values/2020_MITYInc_Website_Pieces_ImproveEachDay_Graphic.png" alt="rocket launching icon")
                h3 Improve Each Day
                p At MITY Inc., we focus on improving customer relationships, improving product innovation to anticipate market needs, and creating new processes that provide greater value and eliminate waste.
            .value
                img(src="../../images/company_values/2020_MITYInc_Website_Pieces_TrustIsKey_Graphic.png" alt="umbrella icon")
                h3 Trust is Key
                p The MITY Inc. leadership works to build trust through their words, actions, and company results. We earn trust through corroboration, transparency, and timely communication.
            .value
                img(src="../../images/company_values/2020_MITYInc_Website_Pieces_YouMatter_Graphic.png" alt="two hands holding heart icon")
                h3 You Matter
                p We want all our employees to return home every day feeling accomplished and valued. We hope they take pride in owning their role and sharing knowledge.

</template>

<style lang="scss">
@import "../../style/mityinc-browser.scss";
.vision-mission-values {
	margin-bottom: -100px;
	margin-top: 100px;
	text-align: center;
	.vision-mission-container {
		background: rgb(219, 230, 236);
		background: linear-gradient(101deg, rgba(219, 230, 236, 1) 0%, rgba(233, 216, 222, 1) 100%);
		padding: 0 1em 6.25em;
		.vision-mission-relative-container {
			margin: auto;
			max-width: 1150px;
			position: relative;
			top: -45px;
			.vision-mission-inner-container {
				display: flex;
				flex-direction: column;
				& > div {
					padding-bottom: 40px;
					h3 {
						font-size: 1.3em;
						font-weight: bold;
						margin: 10px 0;
					}
					p {
						font-size: 1em;
						line-height: 2.1em;
					}
				}
			}
			h2 {
				color: $mity-blue;
				font-size: 1.5em;
			}
		}
	}
	.values-container {
		display: flex;
		flex-direction: column;
		margin: auto;
		max-width: 1160px;
		padding: 0 1em;
		position: relative;
		top: -110px;
		.value {
			margin: 0 auto;
			max-width: 600px;
			img {
				margin-bottom: 15px;
			}
			h3 {
				font-size: 1.3em;
				font-weight: bold;
				margin-bottom: 10px;
			}
			p {
				line-height: 1.9em;
				margin-bottom: 40px;
			}
		}
	}
}
@media (min-width: 850px) {
	.vision-mission-values {
		height: 518px;
		margin-bottom: 275px;
		.vision-mission-container {
			.vision-mission-relative-container {
				.vision-mission-inner-container {
					flex-direction: row;
					justify-content: space-evenly;
					& > div {
						border: 1px solid $white;
						border-radius: 5px;
						margin: 0 1em 2em;
						width: 545px;
						p {
							padding: 0 1em;
						}
					}
				}
			}
		}
		.values-container {
			flex-direction: row;
			justify-content: space-between;
			.value {
				padding: 0 1em;
				width: 260px;
			}
		}
	}
}
</style>
