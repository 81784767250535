<template>
	<div id="#mityinc-app">
		<router-view />
	</div>
</template>

<script>
export default {};
</script>

<style lang="scss"></style>
