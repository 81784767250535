<script>
import $ from "jquery";
import MityIncLogo from "./svg/MITYinc_M_Logo_White-01.vue";

export default {
	components: { MityIncLogo },
	data() {
		return {
			isActive: false,
		};
	},
	mounted() {
		let navbarToggle = $("#navbar-toggle");
		$('a[href*="#"]')
			// Remove links that don't actually link to anything
			.not('[href="#"]')
			.not('[href="#0"]')
			.click(function(event) {
				// On-page links
				if (location.pathname.replace(/^\//, "") === this.pathname.replace(/^\//, "") && location.hostname === this.hostname) {
					// Figure out element to scroll to
					var target = $(this.hash);
					target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
					// Does a scroll target exist?
					if (target.length) {
						// Only prevent default if animation is actually gonna happen
						event.preventDefault();
						navbarToggle.removeClass("active");
						$("html, body").animate(
							{
								scrollTop: target.offset().top - 65,
							},
							750
						);
					}
				}
			});
	},
};
</script>

<template lang="pug">
    header.header
        .container
            a(href="/#home" @click="isActive = false")
                MityIncLogo
            h1.mity-name-text MITY Inc.
            button#navbar-toggle(class="hamburger-icon" @click="isActive = !isActive")
                .bar1(:class="{active:isActive}")
                .bar2(:class="{active:isActive}")
                .bar3(:class="{active:isActive}")
            nav#main-nav(:class="{active:isActive}")
                a(href="/#brands" @click="isActive = false") Our Brands
                a(href="/#partners" @click="isActive = false") Partners
                a(href="/#about" @click="isActive = false") About Us
                a(href="/#careers" @click="isActive = false") Careers
</template>

<style lang="scss">
@import "../style/mityinc-browser.scss";

body {
	min-width: 320px;
}

header.header {
	background-color: $black-transparant-background;
	left: 0;
	margin: 0 auto;
	max-width: 1440px;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 1;
	.container {
		align-items: center;
		display: flex;
		height: 45px;
		margin: 0 auto;
		max-width: 1230px;
		position: relative;
		a {
			padding-left: 1.4em;
			svg {
				background-color: $mity-blue;
				fill: #fff;
				height: 92px;
				margin-top: 57px;
				padding: 1em;
				width: 96px;
				&:hover {
					fill: $font-color;
					background-color: $logo-gray-hover;
				}
				&:active {
					fill: $font-color;
					background-color: $logo-gray-active;
				}
			}
		}
		.mity-name-text {
			align-items: center;
			display: flex;
			flex: 1;
			height: inherit;
			color: $white;
			padding: 0 1em;
		}
		nav {
			align-items: center;
			background-color: $black-transparant-background;
			display: flex;
			flex-direction: column;
			padding: 3em 0;
			position: absolute;
			right: 0;
			top: 45px;
			transform: scaleY(0);
			transition: transform 0.3s ease-out;
			width: 100%;
			z-index: -1;
			a {
				color: $white;
				padding: 0.5em 1em;
				&:hover {
					background-color: $nav-hover-background;
					color: $font-color;
				}
				&:active {
					background-color: $nav-active-background;
					color: $font-color;
				}
			}
		}
		nav.active {
			transform: scaleY(1);
		}
		.hamburger-icon {
			background-color: rgba(0, 0, 0, 0);
			border-color: rgba(0, 0, 0, 0);
			margin: 0;
			padding-right: 1.2em;
			&:hover {
				cursor: pointer;
			}
			div {
				background-color: $white;
				border-radius: 5px;
				height: 5px;
				margin: 6px 0;
				transition: 0.4s;
				width: 35px;
			}
			.active.bar1 {
				-webkit-transform: rotate(-45deg) translate(-9px, 6px);
				transform: rotate(-45deg) translate(-9px, 6px);
			}
			.active.bar2 {
				opacity: 0;
			}
			.active.bar3 {
				-webkit-transform: rotate(45deg) translate(-8px, -8px);
				transform: rotate(45deg) translate(-8px, -8px);
			}
		}
	}
}

@media (min-width: 769px) {
	header.header {
		.container {
			img {
				height: 122px;
				margin-top: 85px;
			}
			.mity-name-text {
				background-color: rgba($color: $mity-blue, $alpha: 0.5);
				flex: none;
				h1 {
					flex: none;
					font-size: 1.4em;
				}
			}
			nav {
				align-items: center;
				background: none;
				display: flex;
				flex: 1;
				flex-direction: row;
				height: 100%;
				justify-content: flex-end;
				position: static;
				transform: none;
				z-index: 0;
				a {
					color: $white;
				}
			}
			.hamburger-icon {
				display: none;
			}
		}
	}
}
@media (min-width: 1024px) {
	header.header .container {
		padding: 0 3.8em;
	}
}
@media (min-width: 1100px) {
	header.header .container {
		padding: 0;
		a {
			padding: 0;
		}
		nav a {
			padding: 0.5em 1.5em;
		}
	}
}
</style>
