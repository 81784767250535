var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "product-pictures" }, [
      _c("img", {
        attrs: {
          src: require("../../images/product_images/product_1.jpg"),
          alt: "product image"
        }
      }),
      _c("img", {
        attrs: {
          src: require("../../images/product_images/product_2.jpg"),
          alt: "product image"
        }
      }),
      _c("img", {
        attrs: {
          src: require("../../images/product_images/product_3.jpg"),
          alt: "product image"
        }
      }),
      _c("img", {
        attrs: {
          src: require("../../images/product_images/product_4.jpg"),
          alt: "product image"
        }
      }),
      _c("img", {
        attrs: {
          src: require("../../images/product_images/product_5.jpg"),
          alt: "product image"
        }
      })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-530fc3f8", { render: render, staticRenderFns: staticRenderFns })
  }
}