var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 325.91 73.11"
      }
    },
    [
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M23.87,63.08H9.67V38.91H24c9.37,0,15.32,4.13,15.32,12.38,0,9.83-9.57,11.79-15.42,11.79M9.67,10H24.88c7.76,0,11.28,4.72,11.28,10.22,0,7.56-5.94,10.42-13.3,10.42H9.67ZM34.15,34.68v-.19c7.25-2.36,12.29-7.37,12.29-15.33,0-11.79-9.27-17.39-21.66-17.39H0V71.34H24.48c13.9,0,25.08-6.88,25.08-19.36,0-9.34-6.85-16.7-15.41-17.3"
        }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M65.23,32.43V10H77.92c7.65,0,15.31,1.76,15.31,11.19S85.57,32.43,77.92,32.43ZM103.5,21.22c0-8.74-4.63-19.45-22.86-19.45H55.56V71.34h9.67V40.68H76L94.13,71.34h12.09L86,39.6c10.78-1.18,17.52-9.54,17.52-18.38"
        }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M143.91,64.26c-16.32,0-26.9-12.48-26.9-27.71s10.58-27.7,26.9-27.7,26.9,12.47,26.9,27.7-10.58,27.71-26.9,27.71m0-64.26c-21.76,0-37.17,15.92-37.17,36.55S122.15,73.1,143.91,73.1s37.17-15.91,37.17-36.55S165.67,0,143.91,0"
        }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M208.27,62.49H196.08V10.62h14.51c23.17,0,27.89,17.09,27.89,25.94,0,17.19-13.9,25.93-30.21,25.93m3-60.72H186.41V71.33h23.27c25.48,0,39.08-17,39.08-34.77,0-12.88-7.35-34.79-37.47-34.79"
        }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M264.6,45.89l.2-29.34h.2l24.93,29.34Zm1-44.12H254.17l0,69.56H265l-.3-17.19H297l14.5,17.19h14.42Z"
        }
      }),
      _c("path", {
        staticClass: "cls-1",
        attrs: {
          d:
            "M310.32,5.28a2.64,2.64,0,0,0-1.22-.21h-.87v2h.91a2.94,2.94,0,0,0,1-.12.86.86,0,0,0,.6-.87.82.82,0,0,0-.39-.81m-1.08-.9a4.44,4.44,0,0,1,1.58.2A1.38,1.38,0,0,1,311.71,6a1.21,1.21,0,0,1-.56,1.11,2.59,2.59,0,0,1-.84.27,1.39,1.39,0,0,1,1,.54,1.59,1.59,0,0,1,.31.87V9.2c0,.13,0,.27,0,.42a.85.85,0,0,0,.06.29l0,.07h-1l0-.06,0-.06,0-.18V9.24A1.37,1.37,0,0,0,310.15,8,2.55,2.55,0,0,0,309,7.78h-.79V10h-1V4.38Zm-3.09-.3a4.17,4.17,0,0,0-1.33,3.12,4.24,4.24,0,0,0,1.32,3.13,4.61,4.61,0,0,0,6.42,0,4.24,4.24,0,0,0,1.32-3.13,4.2,4.2,0,0,0-1.32-3.12,4.63,4.63,0,0,0-6.41,0m6.91,6.74a5.32,5.32,0,0,1-7.43,0,4.89,4.89,0,0,1-1.52-3.63,4.87,4.87,0,0,1,1.53-3.61,5.36,5.36,0,0,1,7.42,0,4.88,4.88,0,0,1,1.54,3.61,4.91,4.91,0,0,1-1.54,3.63"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1b6ff6bc", { render: render, staticRenderFns: staticRenderFns })
  }
}